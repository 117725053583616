//@ts-nocheck
import { useEffect, useState } from 'react';
import { ActiveConstellation, ActiveSatellite, CloseArrow, CloseIcon, GreenSatIcon, HeaderSatIcon, NormalConstellation, NormalGeoFeature, NormalSat, NormalSatellite, OpenArrow } from '../../../commonMMSvgs/MissionModelingSvgs';
import './satelliteCreationModal.css'
import OrbitalDetails from './OrbitalDetails';
import ModalFooter from '../../../commonComponents/ModalFooter';
import ConstellationConfiguration from './ConstellationConfiguration';
import ModalHeader from '../../../commonComponents/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import MissionModellingService from '../../../MissionModelling.service';
import { useParams } from 'react-router-dom';

const SatelliteCreationModal = ({ closeModalCreation }) => {

    const dispatch = useDispatch()
    const params = useParams()
    const {
        mission_id,
        scenario_id
    } = params;

    const editConstellationData = useSelector(state => state.missionModelling.editConstellationObject);

    const [selectedComponent, setSelectedComponent] = useState('Satellite')
    const [satConfOption, setSatConfOption] = useState<any>()
    const [openSideBarModal, setOpenSideBarModal] = useState('')
    const [orbitType, setOrbitType] = useState('Custom')
    const [isRepeating, setIsRepeating] = useState(false)
    const [satConstellationData, setSatConstellationData] = useState<any>()

    const [openedSubSatelliteModal, setOpenSubSatelliteModal] = useState({
        orbit: true,
        configuration: false
    })

    const [orbitalDetails, setOrbitalDetails] = useState({
        altitude: 450.0,
        semiMajorAxis: 6871.0,
        eccentricity: 0.0,
        inclination: 98.0,
        RAAN: 0.0,
        AOP: 0.0,
        TA: 0.0,
        epoch: Math.floor(Date.now() / 1000)
    })

    const subModalOpenposition = () => {
        setOpenSubSatelliteModal({
            orbit: !openedSubSatelliteModal.orbit,
            configuration: !openedSubSatelliteModal.configuration
        })
    }

    const handleSubmit = () => {
        let body;
        if (selectedComponent === 'Satellite') {
            body = {
                "type": "Single",
                "orbitType": orbitType,
                "referenceOrbit": orbitalDetails
            }
        } else {
            body = {
                "type": "Constellation",
                "orbitType": orbitType,
                "constellationProperties": {
                    type: satConstellationData?.constellationType,
                    ...satConstellationData.constellationData
                },
                "referenceOrbit": orbitalDetails
            }
        }

        if (Object.keys(editConstellationData).length === 0) {
            dispatch(MissionModellingService.createConstellations(mission_id, body, closeModalCreation, scenario_id))
        } else {
            dispatch(MissionModellingService.editConstellation(editConstellationData?.constellationId, mission_id, body, closeModalCreation, scenario_id))
        }
    }

    useEffect(() => {
        if (Object.keys(editConstellationData).length > 0) {
            setSelectedComponent(editConstellationData.type === "Constellation" ? "Constellation" : "Satellite")
            setOrbitalDetails(editConstellationData?.groups[0]?.referenceOrbit)
            subModalOpenposition()
        }
    }, [editConstellationData])

    return (
        <>
            <ModalHeader
                icon={<HeaderSatIcon />}
                title={"Satellite"}
                handleClose={() => { closeModalCreation() }}
                handleMinimise={() => { }} />

            <div className='selectionComponentBox'>
                <div className='component'>
                    <div className={`componentSvgContainer ${selectedComponent === 'Satellite' ? "active_component" : ''}`} onClick={() => {
                        setSelectedComponent('Satellite')
                    }}>
                        <ActiveSatellite />
                    </div>
                    <span className={`componentTextStyle ${selectedComponent === 'Satellite' ? '' : 'deactive_text'}`}>
                        Satellite
                    </span>
                </div>
                <div className='component' >
                    <div className={`componentSvgContainer ${selectedComponent === 'Constellation' ? "active_component" : ''}`} onClick={() => {
                        setSelectedComponent('Constellation')
                    }}>
                        <ActiveConstellation />
                    </div>
                    <span className={`componentTextStyle ${selectedComponent === 'Constellation' ? '' : 'deactive_text'}`}>
                        Constellation
                    </span>
                </div>
            </div>

            {selectedComponent !== 'Satellite' &&
                <div className='modal_saperator'>
                    <div className='svgContainerHeader' onClick={() => { subModalOpenposition() }}>
                        {openedSubSatelliteModal.orbit ? <OpenArrow /> : <CloseArrow />}
                    </div>
                    <div className='saperator_header'>
                        Reference Orbit
                    </div>
                </div>}

            {(openedSubSatelliteModal.orbit || selectedComponent === 'Satellite') &&
                <OrbitalDetails
                    orbitalDetails={orbitalDetails}
                    setOrbitalDetails={setOrbitalDetails}
                    setOrbitType={setOrbitType}
                    orbitType={orbitType}
                    isRepeating={isRepeating}
                    setIsRepeating={setIsRepeating}
                />}

            {selectedComponent !== 'Satellite' &&
                <div className='modal_saperator'>
                    <div className='svgContainerHeader' onClick={() => { subModalOpenposition() }}>
                        {openedSubSatelliteModal.configuration ? <OpenArrow /> : <CloseArrow />}
                    </div>
                    <div className='saperator_header'>
                        Constellation Configuration
                    </div>
                </div>}

            {(openedSubSatelliteModal.configuration && selectedComponent !== 'Satellite') &&
                <ConstellationConfiguration
                    orbitalDetails={orbitalDetails}
                    satConstellationData={satConstellationData}
                    setSatConstellationData={setSatConstellationData} />}

            <ModalFooter
                isValid={true}
                actionText={'Create'}
                loadingText={'Creating...'}
                closingText={'Close'}
                handleSubmit={handleSubmit}
                handleClose={() => { closeModalCreation() }}
            />
        </>
    );
};

export default SatelliteCreationModal;
