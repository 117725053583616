import { getEnvVar } from '../../envUtils.ts';
import ApiService from "../../service/ApiService";
import * as mmActions from '../../store/MissionModellingSlice.js'

// ref: https://antaris.atlassian.net/wiki/spaces/ENG/pages/230555665/Geo+Feature+in+ACP


const fetchAois = (missionId) => async (dispatch) => {
    dispatch(mmActions.getAoiRequest());
    try {
        const response = await ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${missionId}/areaOfInterests`);
        dispatch(mmActions?.getAllAoiSucess(response.data));
    } catch (error) {
        dispatch(mmActions?.getAoiFailure());
        console.error('Error fetching aois:', error);
    }
};

const saveAois = (missionId, body) => async (dispatch) => {
    dispatch(mmActions.getAoiRequest());
    try {
        const response = await ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${missionId}/areaOfInterests`, body);
        dispatch(mmActions.getAoiSucess(response.data));
        dispatch(fetchAois(missionId))
    } catch (error) {
        dispatch(mmActions.getAoiFailure());
        console.error('Error fetching aois:', error);
    }
};

const deleteAreaOfInterest = (areaOfInterestId, missionId) => async (dispatch) => {
    dispatch(mmActions.deleteAoiRequest());
    try {
        const response = await ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/areaOfInterest/${areaOfInterestId}`);
        dispatch(mmActions.deleteAoiSuccess())
        dispatch(fetchAois(missionId))
    } catch (error) {
        dispatch(mmActions.deleteAoiFailure());
    }
}

const editAreaOfInterest = (areaOfInterestId, body, mission_id) => async (dispatch) => {
    dispatch(mmActions.editAoiRequest());
    try {
        const response = await ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/areaOfInterest/${areaOfInterestId}`, body);
        dispatch(mmActions.setEditAreaOfInterestObject({}))
        dispatch(mmActions.editAoiSuccess())
        dispatch(fetchAois(mission_id))
    } catch (error) {
        dispatch(mmActions.editAoiFailure());
    }
}

const renameAreaOfInterest = (areaOfInterestId, new_name, mission_id) => async (dispatch) => {
    dispatch(mmActions.renameAoiRequest());
    try {
        const response = await ApiService.patch(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/areaOfInterest/${areaOfInterestId}/rename?areaOfInterestName=${new_name}`);
        dispatch(mmActions.renameAoiSuccess())
        dispatch(fetchAois(mission_id))
    } catch (error) {
        dispatch(mmActions.renameAoiFailure())
        console.log(error)
    }
}

const createConstellations = (missionId, body, closeModalCreation, scenario_id) => async (dispatch) => {
    dispatch(mmActions.createConstellationsRequest())
    try {
        const response = await ApiService.hi(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/constellation?missionid=${missionId}&scenarioid=${scenario_id}`, body);
        dispatch(mmActions.createConstellationsSuccess(response))
        closeModalCreation()
        dispatch(getAllConstellation(missionId, scenario_id))
    } catch (error) {
        dispatch(mmActions.createConstellationsFailure())
        console.log("Error Creating Satellite", error)
    }
}

const getAllConstellation = (missionId, scenario_id) => async (dispatch) => {
    dispatch(mmActions.getConstellationsRequest())
    try {
        const response = await ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/constellation/all?missionid=${missionId}&scenarioid=${scenario_id}`);
        dispatch(mmActions.getConstellationsSuccess(response))
    } catch (error) {
        dispatch(mmActions.getConstellationsFailure())
        console.log("Error Creating Satellite", error)
    }
}

// delete the constellation
const deleteConstellation = (constellationId, missionId, scenario_id) => async (dispatch) => {
    dispatch(mmActions.deleteConstellationRequest())
    try {
        const response = await ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/constellation?missionid=${missionId}&scenarioid=${scenario_id}&constellationid=${constellationId}`);
        dispatch(mmActions.deleteConstellationSuccess())
        dispatch(getAllConstellation(missionId, scenario_id))
        dispatch(getAllOrbit(missionId, scenario_id))
    }
    catch (error) {
        console.log("Error in Deleting Consellation")
        dispatch(mmActions.deleteConstellationFailure())
    }
}

// duplicate the constellation
const duplicateConstellation = (constellationId, missionId, scenario_id) => async (dispatch) => {
    dispatch(mmActions.duplicateConstellationRequest())
    try {
        // API of Duplicate constellation
        const response = await ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/constellation/${constellationId}/clone?missionid=${missionId}&scenarioid=${scenario_id}`);
        dispatch(mmActions.duplicateConstellationSuccess(response))
        dispatch(getAllConstellation(missionId, scenario_id))

    } catch (error) {
        console.log(error)
    }

}

//rename the constellation
const renameConstellation = (constellationId, missionId, scenario_id, body) => async (dispatch) => {
    dispatch(mmActions.renameConstellationRequest())
    try {
        const response = await ApiService.patch(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/constellation/${constellationId}/rename?missionid=${missionId}&scenarioid=${scenario_id}`, body);
        dispatch(mmActions.renameConstellationSuccess(response))
        dispatch(getAllConstellation(missionId, scenario_id))
    }
    catch (error) {
        console.log(error)
    }
}

// Edit the Constellation
const editConstellation = (constellationId, missionId, body, closeModalCreation, scenario_id) => async (dispatch) => {
    dispatch(mmActions.editConstellationRequest())
    try {
        const response = await ApiService.patch(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/constellation/${constellationId}/edit?missionid=${missionId}&scenarioid=${scenario_id}`, body);
        dispatch(mmActions.editConstellationSuccess(response))
        dispatch(getAllConstellation(missionId, scenario_id))
        closeModalCreation()
    }
    catch (error) {
        console.log(error)
        dispatch(mmActions.editConstellationFailure())
    }
}

const editConstellationConfig = (constellationId, missionId, scenario_id, body, closeModalCreation) => async (dispatch) => {
    dispatch(mmActions.editConstellationConfigRequest())
    try {
        const response = await ApiService.patch(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/constellation/${constellationId}/update-config?missionid=${missionId}&scenarioid=${scenario_id}`, body);
        dispatch(mmActions.editConstellationConfigSuccess(response))
        dispatch(getAllConstellation(missionId, scenario_id))
        closeModalCreation()
    } catch (error) {
        console.log(error)
        dispatch(mmActions.editConstellationConfigFailure())
    }
}

// get DeorbitEclipse Report
const getDeorbitEclipseReport = (missionId, constellationId, scenario_id) => async (dispatch) => {
    dispatch(mmActions.getDeorbitEclipseReportRequest())
    try {
        const response = await ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/constellation/${constellationId}/eclipse-deorbit-report?missionid=${missionId}&scenarioid=${scenario_id}`);
        dispatch(mmActions.getDeorbitEclipseReportSuccess(response.data))
    } catch (error) {
        dispatch(mmActions.getDeorbitEclipseReportFailure())
        console.log("Error Creating Satellite", error)
    }
}

const getAllGroundStation = () => async (dispatch) => {
    dispatch(mmActions.getAllGroundStationRequest())
    try {
        const response = await ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation`);
        dispatch(mmActions.getAllGroundStationSuccess(response))
    } catch (error) {
        dispatch(mmActions.getAllGroundStationFailure())
        console.log(error)
    }
}

const createGroundStation = (name, body, closeModalCreation) => async (dispatch) => {
    dispatch(mmActions.createGroundStationRequest())
    try {
        const response = await ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation?groundStationName=${name}`, body);
        dispatch(mmActions.createGroundStationSuccess(response))
        closeModalCreation()
        dispatch(getAllGroundStation())
    } catch (error) {
        console.log(error)
        dispatch(mmActions.createGroundStationFailure())
    }
}

const deleteGroundStation = (groundStationId) => async (dispatch) => {
    dispatch(mmActions.deleteGroundStationRequest())
    try {
        const response = await ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation?groundStationId=${groundStationId}`);
        dispatch(mmActions.deleteGroundStationSuccess(response))
        dispatch(getAllGroundStation())
    }
    catch (error) {
        console.log(error)
        dispatch(mmActions.deleteGroundStationFailure())
    }
}

const renameGroundStation = (groundStationId, groundStationName) => async (dispatch) => {
    dispatch(mmActions.renameGroundStationRequest())
    try {
        const response = await ApiService.patch(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/gs-update/${groundStationId}/rename?groundStationName=${groundStationName}`);
        dispatch(mmActions.renameGroundStationSuccess(response))
        dispatch(getAllGroundStation())
    }
    catch (error) {
        console.log(error)
        dispatch(mmActions.renameGroundStationFailure())
    }
}

const editGroundStation = (groundStationId, groundStationBody, closeModalCreation) => async (dispatch) => {
    dispatch(mmActions.editGroundStationRequest())
    try {
        const response = await ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/gs-update`, groundStationBody);
        dispatch(mmActions.editGroundStationSuccess(response))
        closeModalCreation()
        dispatch(getAllGroundStation())
    }
    catch (error) {
        console.log(error)
        dispatch(mmActions.editGroundStationFailure())
    }
}

const getCoverageAnalysisVisualisationData = (mission_id, scenario_id, body) => async (dispatch) => {
    dispatch(mmActions.getCoverageOrbitDataRequest())
    delete body.aoiobjects;
    try {
        const response = await ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/analysis/coverage-orbits?missionid=${mission_id}&scenarioid=${scenario_id}`, body);
        dispatch(mmActions.getCoverageOrbitDataSuccess(response))
    } catch (error) {
        console.log(error)
        dispatch(mmActions.getCoverageOrbitDataFailure())
    }
}

const coverageAnalysis = (mission_id, body, scenario_id, closeModalCreation) => async (dispatch) => {
    dispatch(mmActions.coverageAnalysisRequest())
    try {
        const response = await ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/analysis/coverage?missionid=${mission_id}&scenarioid=${scenario_id}`, body);
        dispatch(mmActions.coverageAnalysisSuccess(response.data))
        dispatch(getCoverageAnalysisReport(mission_id, scenario_id))
        dispatch(getCoverageAnalysisVisualisationData(mission_id, scenario_id, body))
        closeModalCreation()
    } catch (error) {
        console.log(error)
        dispatch(mmActions.coverageAnalysisFailure())
        closeModalCreation()
    }
}

const getCoverageAnalysisReport = (mission_id, scenario_id) => async (dispatch) => {
    dispatch(mmActions.coverageAnalysisReportRequest())
    try {
        const response = await ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/report/coverage?missionid=${mission_id}&scenarioid=${scenario_id}`);
        dispatch(mmActions.coverageAnalysisReportSuccess(response.data))
        dispatch(mmActions.setLatestReport('Coverage'))
    } catch (error) {
        console.log(error)
        dispatch(mmActions.coverageAnalysisReportFailure())
    }
}

const getDeorbitEclipseStatusOverview = (mission_id, scenario_id) => async (dispatch) => {
    dispatch(mmActions.getStatusOverviewOfOrbitEclipseRequest())
    try {
        const response = await ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/constellation/all/status-overview?missionid=${mission_id}&scenarioid=${scenario_id}`);
        dispatch(mmActions.getStatusOverviewOfOrbitEclipseSuccess(response.data))
    } catch (error) {
        console.log(error)
        dispatch(mmActions.getStatusOverviewOfOrbitEclipseFailure())
    }
}

const getAllOrbit = (missionId, scenario_id) => async (dispatch) => {
    dispatch(mmActions.getAllOrbitRequest())
    try {
        const response = await ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/constellation/all/orbit?missionid=${missionId}&scenarioid=${scenario_id}`);
        dispatch(mmActions.getAllOrbitSuccess(response))
    } catch (error) {
        console.log(error)
        dispatch(mmActions.getAllOrbitFailue())
    }
}

const groundStationAnalysis = (missionId, scenario_id, closeModalCreation, body) => async (dispatch) => {
    dispatch(mmActions.getGroundStationReportRequest())
    try {
        const response = await ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/analysis/groundstation?missionid=${missionId}&scenarioid=${scenario_id}`, body);
        dispatch(mmActions.getGroundStationReportSuccess(response.data))
        dispatch(groundStationAnalysisReport(missionId, scenario_id, closeModalCreation))
    } catch (error) {
        dispatch(mmActions.getGroundStationReportFailure())
        console.log(error)
    }
}

const groundStationAnalysisReport = (missionId, scenario_id, closeModalCreation) => async (dispatch) => {
    dispatch(mmActions.getGroundStationReportDataRequest())
    try {
        const response = await ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/reports/groundstation?missionid=${missionId}&scenarioid=${scenario_id}`);
        dispatch(mmActions.getGroundStationReportDataSuccess(response.data))
        dispatch(mmActions.setLatestReport("Ground Contact"))
        dispatch(getGroundStationReportVisualisation(missionId, scenario_id))
        closeModalCreation()
    } catch (error) {
        dispatch(mmActions.getGroundStationReportDataFailure())
        console.log(error)
    }
}

const getGroundStationReportVisualisation = (missionId, scenario_id) => async (dispatch) => {
    dispatch(mmActions.getGSVisualisationDataRequest())
    try {
        const response = await ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}/mission-simulation/analysis-visualization-report/groundstation?missionid=${missionId}&scenarioid=${scenario_id}`);
        dispatch(mmActions.getGSVisualisationDataSuccess(response.data))
    } catch (error) {
        dispatch(mmActions.getGSVisualisationDataFailure())
        console.log(error)
    }
}

const MissionModellingService = {
    fetchAois,
    saveAois,
    deleteAreaOfInterest,
    renameAreaOfInterest,
    editAreaOfInterest,

    createConstellations,
    renameConstellation,
    deleteConstellation,
    duplicateConstellation,
    getAllConstellation,
    editConstellation,
    editConstellationConfig,

    getAllGroundStation,
    createGroundStation,
    renameGroundStation,
    deleteGroundStation,
    editGroundStation,

    coverageAnalysis,

    getDeorbitEclipseReport,
    getCoverageAnalysisReport,

    getDeorbitEclipseStatusOverview,
    getAllOrbit,
    groundStationAnalysis
};

export default MissionModellingService;
