import { Fragment, useEffect, useState } from "react";
import * as Cesium from "cesium";
import { useParams } from "react-router-dom";
import "./scenarioOrbit.css"
import useScenarioViewer from "../hooks/useScenarioViewer";
import ViewerSettings from "./ViewerSettings";
import { scenario_visualization } from "./DataSources";
import { truncateNumber } from "../../../../../CommonComponents/CommonFunctions";

const ScenarioOrbitNew = ({
  all_ground_stations,
  scenarioData,
}) => {
  const params = useParams();
  const {
    scenario_id,
  } = params;

  const { viewer, setViewer } = useScenarioViewer(null)
  const [groundTargets, setGroundTargets] = useState(null)

  const [onSideGroundStation, setOnSideGroundStation] = useState(null)

  // reset rhe position of viewer
  const resetPosition = (viewer, position) => {
    viewer.camera.flyTo({
      destination: Cesium.Cartesian3.fromDegrees(0, 0, position)
    });
  }

  let imaginary = new Cesium.MapboxStyleImageryProvider({
    styleId: 'dark-v10',
    accessToken: 'pk.eyJ1IjoiYW50YXJpcy1tYXBib3giLCJhIjoiY2x2cGNubzF4MDBveTJtb2RtNG5zMGQ2NCJ9.MkPyl-z2FXuFSyYNwP_oaw'
  });

  // this useEffect call only one time once viewer is created then it will not call. purpose ==> prevent the re-rendring
  useEffect(() => {
    if (viewer === null) {
      let cesium_viewer = (new Cesium.Viewer("scenariocesiumContainer", {
        requestRenderMode: true,
        shouldAnimate: true,
        animation: false, // Disable the default animation widget
        baseLayerPicker: false, // Disable the default base layer picker
        timeline: false, // Disable the timeline
        geocoder: false, // Disable the geocoder
        homeButton: false, // Disable the home button
        navigationHelpButton: false, // Disable the navigation help button
        sceneModePicker: false, // Disable the scene mode picker
        selectionIndicator: false, // Disable the selection indicator
        fullscreenButton: false,
        imageryProvider: imaginary,
      }));

      cesium_viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

      if (imaginary) {
        cesium_viewer.scene.globe.baseColor = Cesium.Color.TRANSPARENT
      }

      const map_details = JSON.parse(sessionStorage.getItem('map_details'))
      if (map_details?.[scenario_id]?.['View'] === '3D') {
        cesium_viewer.scene.morphTo3D(0)
      } else {
        cesium_viewer.scene.morphTo2D(0)
      }
      cesium_viewer.scene.globe.showGroundAtmosphere = false;
      cesium_viewer.clock.currentTime = cesium_viewer.clock.startTime;
      cesium_viewer.clock.shouldAnimate = true;
      cesium_viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;
      cesium_viewer.scene.screenSpaceCameraController.enableRotate = true;
      cesium_viewer.scene.screenSpaceCameraController.enableZoom = true;
      cesium_viewer.scene.screenSpaceCameraController.enableTilt = true;
      cesium_viewer.scene.screenSpaceCameraController.enableLook = true;
      cesium_viewer.scene.highDynamicRange = false

      resetPosition(cesium_viewer, 30000000)
      setViewer(cesium_viewer)
    }
  }, [viewer]);


  // when the viewer is ready to use then this useEffect will call handler
  useEffect(() => {
    if (viewer) {
      const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      handler.setInputAction((movement) => {
        const pickedObject = viewer.scene.pick(movement.endPosition);
        if (Cesium.defined(pickedObject) && Cesium.defined(pickedObject.id)) {
          let entity = pickedObject.id;
          viewer.selectedEntity = entity;
          if (entity && entity?._id) {
            setOnSideGroundStation(entity && entity?._id)
            if (entity.id.includes('ground_target_') && entity.position) {
              let currentTime = Cesium.JulianDate.now();
              let position = entity.position.getValue(currentTime);
              if (position) {
                let cartographicPosition = Cesium.Cartographic.fromCartesian(position);
                let longitude = Cesium.Math.toDegrees(cartographicPosition.longitude);
                let latitude = Cesium.Math.toDegrees(cartographicPosition.latitude);
                setGroundTargets([{
                  id: entity.id,
                  name: entity.name,
                  longitude: longitude,
                  latitude: latitude,
                }])
              }
            }
          }
        } else {
          setOnSideGroundStation(null)
          setGroundTargets(null)
        }

      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      return () => {
        handler.destroy();
      };
    }
  }, [viewer])




  useEffect(() => {
    document.body.style.userSelect = 'none';
    return (() => { document.body.style.userSelect = 'auto'; })
  }, [])

  return (
    <Fragment>
      {onSideGroundStation !== null && viewer && all_ground_stations?.map(station => {

        if (station?.['groundStationId'] === onSideGroundStation && station?.properties?.latitude && station?.properties?.longitude) {
          var latitude = Number(station?.properties?.latitude);
          var longitude = Number(station?.properties?.longitude);

          var cartographic = Cesium.Cartographic.fromDegrees(longitude, latitude);
          var cartesianPosition = Cesium.Cartographic.toCartesian(cartographic);
          const popupPosition = Cesium.SceneTransforms.wgs84ToWindowCoordinates(
            viewer.scene,
            cartesianPosition
          );

          let outside_window_x = (popupPosition.x + 610) > window.innerWidth;
          const top_position = station?.['properties']?.['freqbands']?.length === 3 ? 200 : station?.['properties']?.['freqbands']?.length === 2 ? 170 : 140

          let outside_window_y = popupPosition.y - top_position < 0;
          let top = outside_window_y ? `${((popupPosition.y - top_position) - (popupPosition.y - top_position) + 50)}px` : outside_window_x ? `${popupPosition.y - top_position + 35}px` : `${popupPosition.y - top_position + 50}px`;
          let left = outside_window_x ?
            outside_window_y ? `${popupPosition.x - 620}px` : `${(popupPosition.x + window.innerWidth) - (popupPosition.x + 610)}px` :
            outside_window_y ? `${popupPosition.x + 20}px` : `${popupPosition.x + 10}px`;

          return (
            <div className="popup-modal"
              key={station?.['groundStationId']}
              style={{
                display: 'flex',
                cursor: 'default',
                left: left,
                top: top,
                transition: 'all 0.3s ease',
              }}
            >
              <div className='py-2 px-4 d-flex align-items-center w-100 justify-content-between'>
                <div className='d-flex align-items-start flex-column w-55 '>
                  <div className='d-flex align-items-center w-98'>
                    <span className='fs-14 w-auto color-atmos text-truncate' title={station?.['groundStationName']}>
                      {station?.['groundStationName']}
                    </span>
                    <span className='fs-14 w-auto text-truncate' title={station?.properties?.country?.includes(',') ? station?.properties?.country.split(",")[1] : station?.properties?.country}>
                      {`, ${station?.properties?.country?.includes(',') ? station?.properties?.country.split(",")[1] : station?.properties?.country}`}
                    </span>
                  </div>
                  <span className='fs-12 color_history text-truncate'
                    title={(station?.['properties']?.['provider'] && station?.['properties']?.['provider'] !== '') ? station?.['properties']?.['provider'] : 'Standalone'}>
                    {(station?.['properties']?.['provider'] && station?.['properties']?.['provider'] !== '') ?
                      station?.['properties']?.['provider']
                      :
                      'Standalone'
                    }
                  </span>
                </div>
                <div className='d-flex align-items-center gap-3'>
                  {/* <div className='d-flex gap-1 align-items-center'>
                    <span className='color_history'>Antenna Size:</span>
                    <span className='fs-13'>{station?.properties?.antenaSize}</span>
                  </div> */}
                  <div className='d-flex gap-1 align-items-center'>
                    <span className='color_history'>Lat:</span>
                    <span className='fs-13'>{station?.properties?.latitude}</span>
                  </div>
                  <div style={{ width: '0.5px', height: '20px', background: 'rgba(240,240,240,0.20)' }} />
                  <div className='d-flex gap-1 align-items-center'>
                    <span className='color_history'>Lon:</span>
                    <span className='fs-13'>{station?.properties?.longitude}</span>
                  </div>
                </div>
              </div>
              <div className='modal_devider' />
              <div className='w-100 d-flex align-items-center flex-column gap-3 px-4 py-3'>
                <div className='d-flex align-items-center justify-content-between w-100'>
                  <span className='color_history fs-13 w-10'>Bands</span>
                  <span className='color_history fs-13 w-20 align-items-center justify-content-center d-flex'>Rx Freq.</span>
                  <span className='color_history fs-13 w-20 align-items-center justify-content-center d-flex'>Tx Freq.</span>
                  <span className='color_history fs-13 w-15 align-items-center justify-content-center d-flex'>G/T (dB/K)</span>
                  <span className='color_history fs-13 w-20 align-items-center justify-content-center d-flex'>EIRP (dBW)</span>
                  <span className='color_history fs-13 w-15 align-items-center d-flex'>Polarization</span>
                </div>
                {station?.['properties']?.['freqbands']?.map((band, index) => {
                  return (
                    <div className='d-flex align-items-center justify-content-between w-100' key={index}>
                      <span className='color_history fs-12 w-10'>{band}</span>
                      <span className='fs-12 w-20 align-items-center justify-content-center d-flex'>
                        {station?.['properties']?.['rxFreq']?.[`${band?.split('-')[0]}freq`]?.['min'] ? `${station?.['properties']?.['rxFreq']?.[`${band?.split('-')[0]}freq`]?.['min']} - ${station?.['properties']?.['rxFreq'][`${band?.split('-')[0]}freq`]?.['max']}` : '-'}
                      </span>
                      <span className='fs-12 w-20 align-items-center justify-content-center d-flex'>
                        {station?.['properties']?.['txFreq']?.[`${band?.split('-')[0]}freq`]?.['min'] ? `${station?.['properties']?.['txFreq']?.[`${band?.split('-')[0]}freq`]?.['min']} - ${station?.['properties']?.['txFreq'][`${band?.split('-')[0]}freq`]?.['max']}` : '-'}
                      </span>
                      <span className='fs-12 w-15 align-items-center justify-content-center d-flex'>{station?.['properties']?.[`${band === 'UHF' ? 'U' : band.split('-')[0]}_GT`] ? station?.['properties']?.[`${band === 'UHF' ? 'U' : band.split('-')[0]}_GT`] : '-'}</span>
                      <span className='fs-12 w-20 align-items-center justify-content-center d-flex'>{station?.['properties']?.[`${band === 'UHF' ? 'U' : band.split('-')[0]}_EIRP`] ? station?.['properties']?.[`${band === 'UHF' ? 'U' : band.split('-')[0]}_EIRP`] : '-'}</span>
                      <span className='fs-12 w-15 align-items-center text-truncate'>{station?.['properties']?.[`polarization`] ? (station?.['properties']?.[`polarization`]?.length > 10 ? station?.['properties']?.[`polarization`]?.slice(0, 10) + ".." : station?.['properties']?.[`polarization`]) : '-'}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        }
      })}

      {viewer && groundTargets && groundTargets?.map(target => {
        let targetName = target?.['name'];
        let targetLat = target?.latitude;
        let targetLong = target?.longitude;

        var cartographic = Cesium.Cartographic.fromDegrees(targetLong, targetLat);
        var cartesianPosition = Cesium.Cartographic.toCartesian(cartographic);
        const popupPosition = Cesium.SceneTransforms.wgs84ToWindowCoordinates(
          viewer.scene,
          cartesianPosition
        );

        let outside_window_x = (popupPosition.x + 360) > window.innerWidth;
        const top_position = 50
        let outside_window_y = popupPosition.y - top_position < 0;
        let top = outside_window_y ? `${((popupPosition.y - top_position) - (popupPosition.y - top_position) + 0)}px` : outside_window_x ? `${popupPosition.y - top_position + 70}px` : `${popupPosition.y - top_position + 50}px`;
        let left = outside_window_x ?
          outside_window_y ? `${popupPosition.x - 360}px` : `${(popupPosition.x + window.innerWidth) - (popupPosition.x + 370)}px` :
          outside_window_y ? `${popupPosition.x + 20}px` : `${popupPosition.x + 10}px`;
        return (
          <div className="popup-modal"
            key={target?.['groundTargetId']}
            style={{
              display: 'flex',
              flexDirection: "row",
              alignContent: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
              left: left,
              top: top,
              transition: 'all 0.3s ease',
              padding: '12px',
              gap: '16px',
              width: 'auto',
              height: '33px'
            }}>
            <div style={{ display: 'flex', flexDirection: 'column', }}>
              <span title={targetName}
                style={{
                  color: 'rgba(204, 245, 78, 1.00)', fontSize: '14px', letterSpacing: '0.56px',
                  maxWidth: '150px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                {targetName}
              </span>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', maxWidth: '200px' }}>
              <div className="labelClass">
                <span style={{ color: '#EEE', fontSize: '10px', opacity: '0.6' }}>Lat:</span>
                <span style={{ color: '#F0F0F0', fontSize: '12px', letterSpacing: '0.56px', marginLeft: '4px' }}>
                  {truncateNumber(targetLat, 2)}
                </span>
              </div>
              <div style={{ padding: '0px 8px', alignItems: 'center' }}>
                <img style={{ width: '1px' }} src={require("../../../../../../assets/images/svgs/line.svg").default} />
              </div>
              <div className="labelClass">
                <span style={{ color: '#EEE', fontSize: '10px', opacity: '0.6' }}>
                  Lon:
                </span>
                <span className="labelClass"
                  style={{ color: '#F0F0F0', fontSize: '12px', letterSpacing: '0.56px', marginLeft: '4px' }}>
                  {truncateNumber(targetLong, 2)}
                </span>
              </div>

            </div>
          </div >
        )


      })}

      <div id="scenariocesiumContainer" className="d-flex h-100 w-100" onClick={() => { scenario_visualization().removed_focus_gs(viewer, scenarioData?.['ground_stations']) }} />

      <ViewerSettings />
    </Fragment>
  );
};

export default ScenarioOrbitNew;

