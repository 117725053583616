import { useEffect, useState } from 'react';
// import ModalDropdown from '../../../../MissionModeling/Modals/ModalComponents/ModalDropdown';
import './orbitalDetails.css'
import { DatePicker } from 'rsuite';
import ModalInput from '../../../commonComponents/ModalInput';
import ModalDropdown from '../../../commonComponents/ModalDropdown';
import { Form } from 'react-bootstrap';


const OrbitalDetails = ({ orbitalDetails, setOrbitalDetails, setOrbitType, orbitType, isRepeating, setIsRepeating }) => {
    const [isPreviousSelected, setIsPreviousSelected] = useState('Custom')

    const handleDateChange = (date) => {
        if (date) {
            const utcDate = new Date(date).toISOString();
            const epochTime = new Date(utcDate).getTime();
            setOrbitalDetails({
                ...orbitalDetails,
                epoch: (epochTime / 1000)
            })
        }
    };

    const option = [{ label: "Custom", value: "Custom" }, { label: "SSO", value: "SSO" }, { label: "Polar", value: "Polar" }]

    const handleSelectedOrbitType = (e) => {
        setOrbitType(e.label)
    }

    const handleSMAChange = (value) => {
        setOrbitalDetails({
            ...orbitalDetails,
            semiMajorAxis: value
        })
    }

    const handleEccentricityChange = (value) => {
        setOrbitalDetails({
            ...orbitalDetails,
            eccentricity: value
        })
    }

    const handleInclinationChange = (value) => {
        setOrbitalDetails({
            ...orbitalDetails,
            inclination: value
        })
    }

    const handleLANChange = (value) => {
        setOrbitalDetails({
            ...orbitalDetails,
            RAAN: value
        })
    }

    const handleAOPChange = (value) => {
        setOrbitalDetails({
            ...orbitalDetails,
            AOP: value
        })
    }

    const handleTAChange = (value) => {
        setOrbitalDetails({
            ...orbitalDetails,
            TA: value
        })
    }

    const handleOrbitsChange = (value) => {
        setOrbitalDetails({
            ...orbitalDetails,
            orbits: value
        })
    }

    return (
        <div className='orbital_body'>
            <span className='orbital_body_title'> Orbital path type </span>
            <div style={{ width: "100%" }}>
                <ModalDropdown
                    options={option}
                    isMulti={false}
                    handleSelectedOption={handleSelectedOrbitType}
                    previousOption={isPreviousSelected}
                />
            </div>

            {/* <div className='comms_panel_band'>
                <Form.Check
                    type="checkbox"
                    label=""
                    checked={isRepeating}
                    onChange={(e) => {
                        setIsRepeating(!isRepeating)
                    }}
                />
                <span>Repeating</span>
            </div> */}

            <span className='orbital_body_title'> Orbital Details </span>
            <div className='orbital_body_item'>
                <span>Epoch</span>
                <div>
                    <DatePicker
                        format="MM/dd/yyyy HH:mm:ss"
                        onChange={(date) => {
                            handleDateChange(date)
                        }}
                        value={new Date(orbitalDetails.epoch * 1000)}
                        cleanable={false}
                    />
                </div>
            </div>
            <div className='orbital_body_item'>
                <span>SemiMajor Axis(a)</span>
                <div>
                    <ModalInput
                        unit={'km'}
                        handleChange={handleSMAChange}
                        value={orbitalDetails.semiMajorAxis} />
                </div>
            </div>

            {<div className='orbital_body_item'>
                <span>Eccentricity (e)</span>
                <div>
                    <ModalInput
                        handleChange={handleEccentricityChange}
                        value={orbitalDetails.eccentricity} />
                </div>
            </div>}

            {<div className='orbital_body_item'>
                <span>Inclination (i)</span>
                <div>
                    <ModalInput
                        unit={'°'}
                        handleChange={handleInclinationChange}
                        value={orbitalDetails.inclination}
                        disabled={!(orbitType === 'Custom')}
                    />
                </div>
            </div>}

            <div className='orbital_body_item'>
                <span>Longitude ascending node (Ω)</span>
                <div>
                    <ModalInput
                        unit={'°'}
                        handleChange={handleLANChange}
                        value={orbitalDetails.RAAN} />
                </div>
            </div>

            {<div className='orbital_body_item'>
                <span>Argument of periapsis (ω)</span>
                <div>
                    <ModalInput
                        unit={'°'}
                        handleChange={handleAOPChange}
                        value={orbitalDetails.AOP} />
                </div>
            </div>}

            {<div className='orbital_body_item'>
                <span>True Anomaly (ν)</span>
                <div>
                    <ModalInput
                        unit={'°'}
                        handleChange={handleTAChange}
                        value={orbitalDetails.TA} />
                </div>
            </div>}
        </div>
    );
};

export default OrbitalDetails;
