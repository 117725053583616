import ModalInput from "../../../commonComponents/ModalInput";

const CustomConstellation = ({ customConstellation, setCustomConstellation }) => {

    const handleNoOfPlaneChange = (value) => {
        setCustomConstellation({
            ...customConstellation,
            noOfPlanes: value
        })
    }

    const handleSatPerPlaneChange = (value) => {
        setCustomConstellation({
            ...customConstellation,
            noOfSatellitesPerPlane: value
        })
    }

    const handlePlaneSaperationChange = (value) => {
        setCustomConstellation({
            ...customConstellation,
            separationAngleBetweenPlane: value
        })
    }

    const handlePlaneInclinationChange = (value) => {
        setCustomConstellation({
            ...customConstellation,
            inclinationOfPlane: value
        })
    }

    const handleRaanChange = (value) => {
        setCustomConstellation({
            ...customConstellation,
            RAAN: value
        })
    }

    return (
        <div style={{ width: "100%" }}>
            <div className='orbital_body_item'>
                <span>Number of Planes</span>
                <div>
                    <ModalInput
                        handleChange={handleNoOfPlaneChange}
                        value={customConstellation.noOfPlanes} />
                </div>
            </div>

            <div className='orbital_body_item'>
                <span>Satellites per Plane</span>
                <div>
                    <ModalInput
                        handleChange={handleSatPerPlaneChange}
                        value={customConstellation.noOfSatellitesPerPlane} />
                </div>
            </div>

            <div className='orbital_body_item'>
                <span>Plane Separation Angle(Δθ)</span>
                <div>
                    <ModalInput
                        unit={'0'}
                        handleChange={handlePlaneSaperationChange}
                        value={customConstellation.separationAngleBetweenPlane} />
                </div>
            </div>

            <div className='orbital_body_item'>
                <span>Plane Inclination (i)</span>
                <div>
                    <ModalInput
                        handleChange={handlePlaneInclinationChange}
                        value={customConstellation.inclinationOfPlane} />
                </div>
            </div>

            <div className='orbital_body_item'>
                <span>RAAN</span>
                <div>
                    <ModalInput
                        unit={'0'}
                        handleChange={handleRaanChange}
                        value={customConstellation.RAAN} />
                </div>
            </div>

        </div>
    );
};

export default CustomConstellation
    ;
