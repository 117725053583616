//@ts-nocheck
import { useState, useEffect } from 'react';
import { Canvas } from 'react-three-fiber';
import { OrbitControls } from '@react-three/drei';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import MissionService from '../../../../../service/MissionService';
import Common3DSat from "../../../../CommonComponents/Common3DSat";
import Loader from '../../../../../Layouts/Loader/Loader';
import { toast } from "react-toastify";
import NewLoader from '../../../../CommonComponents/NewLoader';
import zIndex from '@mui/material/styles/zIndex';

const FileUploader = ({ Satellite, busSizeUsage, payloadSizeUsage, isCloneTemplate }) => {
    const [model, setModel] = useState(null);
    const [modelName, setModelName] = useState('')
    const [hover, setHover] = useState(false)
    const [newLoading, setNewLoading] = useState(true)


    const loadModel = (modelUrl) => {
        const loader = new GLTFLoader();
        try {
            loader.load(modelUrl, (gltf) => {
                setModel(gltf.scene)
                setNewLoading(false);
            })
        } catch (error) {
            toast.error("Error loading model:", error);
            setNewLoading(false);
        }
    }

    const getSatelliteById = async (satelliteId) => {
        try {
            const response = await MissionService.get_satellite_by_sat_id(satelliteId);
            const satModelName = response?.data.modelNames.modelName;
            if (satModelName) {
                await getModelUrlByName(Satellite.satellite_Id, satModelName);
            } else {
                setModel(null);
                setNewLoading(false);
            }
        } catch (error) {
            console.log("Error fetching satellite by ID:", error);
            setModel(null);
            setNewLoading(false);
        }
    };

    const getModelUrlByName = async (satelliteId, modelName) => {
        try {
            const response = await MissionService.getModelUrl(satelliteId, modelName);
            if (response?.data) {
                setModelName(modelName);
                loadModel(response?.data);
            } else {
                setModel(null);
                setNewLoading(false);
            }
        } catch (error) {
            console.log("Error fetching model URL:", error);
            setModel(null);
            setNewLoading(false);
        }
    };

    const fetchModelData = async () => {
        setNewLoading(true);
        await getSatelliteById(Satellite.satellite_Id);
    };

    useEffect(() => {
        if (Satellite.satellite_Id) {
            fetchModelData();
        }
    }, [Satellite.satellite_Id]);

    const post3Dmodel = async (satellite_Id, formData) => {
        try {
            const response = await MissionService.postThreedModel(satellite_Id, formData)
            return response?.data
        } catch (error) {
            return null;
        }
    }

    const handleButtonClick = () => {
        document.getElementById('file-input')?.click()
    }

    async function uploadFileInChunks(file) {
        const chunkSize = 10 * 1024 * 1024; // 10MB
        const totalChunks = Math.ceil(file.size / chunkSize);
        const uploadPromises = []; // Array to hold upload promises

        for (let i = 0; i < totalChunks; i++) {
            const start = i * chunkSize;
            const end = Math.min(file.size, start + chunkSize);
            const chunk = file.slice(start, end);

            const formData = new FormData();
            formData.append('fileName', file.name);
            formData.append('chunkNumber', i);
            formData.append('object', chunk);
            formData.append('totalParts', totalChunks);

            // Create a promise for the fetch operation
            const uploadPromise = await post3Dmodel(Satellite.satellite_Id, formData)
            uploadPromises.push(uploadPromise)
        }

        try {
            // Wait for all uploads to complete
            await Promise.all(uploadPromises);
            fetchModelData()
        } catch (error) {
            console.error(error);
            toast.error("Upload failed, please try again later");
            setModel(null);
            setModelName('')
            setNewLoading(false);
        }
    }

    const handleFileChange = async (e) => {
        const selectedFile = e.target.files[0];
        const fileSize = selectedFile.size;
        const maxSize = 100 * 1024 * 1024; // 50MB
        if (selectedFile && fileSize <= maxSize) {
            setNewLoading(true)
            await uploadFileInChunks(selectedFile)
        } else {
            toast.error('Size of the model should be less than 100MB.');
        }
    }

    const reloadDefaultModel = () => {
        setNewLoading(true)
        MissionService.deleteSatelliteBucket(Satellite.satellite_Id).then((res) => {
            setModel(null)
            setModelName('')
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setNewLoading(false)
        })
    }

    return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            {newLoading && <NewLoader canvasHeight={'100vh'} canvasWidth={'50vw'} />}
            <input
                id="file-input"
                type="file"
                accept=".glb,.gltf"
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
            {!model && (
                <div
                    onClick={handleButtonClick}
                    onMouseEnter={() => {
                        setHover(true)
                    }}
                    onMouseLeave={() => {
                        setHover(false)
                    }}
                    className="btn z-99"
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: '50pt',
                        left: 'auto',
                        display: 'inline-flex',
                        height: 'var(--40, 40pt)',
                        padding: '12pt var(--8, 8pt)',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 'var(--4, 4pt)',
                        border: '0.5pt solid rgba(240, 240, 240, 0.30)',
                        backgroundColor: hover ? 'rgba(204, 245, 78, 0.20)' : '#0A0A0A',
                        boxShadow: '0pt 4pt 10pt 0pt rgba(0, 0, 0, 0.30)',
                        visibility: model ? true : false,
                        zIndex: 999,
                        pointerEvents: newLoading ? 'none' : 'auto',
                        opacity: newLoading ? 0.5 : 1,
                        cursor: newLoading ? 'auto' : 'pointer',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            width: 'var(--24, 24pt)',
                            height: 'var(--24, 24pt)',
                            padding: '6pt',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12pt',
                            borderRadius: 'var(--4, 4pt)'
                        }}
                    >
                        <img src={require('../../../../../assets/images/svgs/upload_model.svg').default} alt="Upload Model" style={{ width: '14pt', height: '14.457pt' }} />

                    </div>
                    <span
                        style={{
                            color: '#F0F0F0',
                            fontFamily: 'Montserrat',
                            fontSize: '12pt',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            letterSpacing: '0.48pt',
                        }}
                    >3D Model
                    </span>
                </div>
            )}
            {model && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: '50px',
                        left: 'auto',
                        display: 'flex',
                        width: '320px',
                        padding: 'var(--8, 8px) var(--4, 4px) var(--8, 8px) 12px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderRadius: 'var(--4, 4px)',
                        border: '0.5px solid rgba(240, 240, 240, 0.50)',
                        background: 'linear-gradient(0deg, rgba(10, 10, 10, 0.50) 0%, rgba(10, 10, 10, 0.50) 100%), rgba(255, 255, 255, 0.00)',
                        zIndex: '999',
                        pointerEvents: newLoading ? 'none' : 'auto',
                        opacity: newLoading ? 0.5 : 1
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        title={modelName}
                    >
                        <img src={require('../../../../../assets/images/svgs/satModelIcon.svg').default} alt="3D Model" style={{ opacity: 0.6, width: 'var(--20, 20px)', height: 'var(--20, 20px)' }} />
                        <span
                            style={{
                                color: '#F0F0F0',
                                fontFamily: 'Montserrat',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                letterSpacing: '0.56px',
                                marginLeft: '2px',
                                width: '150px',
                                whiteSpace: 'nowrap',  // Prevent text wrapping
                                overflow: 'hidden',  // Hide overflow
                                textOverflow: 'ellipsis'  // Show ellipsis when text overflows
                            }}

                        >
                            {modelName}
                        </span>

                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 'var(--4, 4px)'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                height: 'var(--24, 24px)',
                                padding: '3px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px',
                                cursor: newLoading ? 'pointer' : 'auto',
                            }}
                            onClick={handleButtonClick}
                        >

                            <img src={require('../../../../../assets/images/svgs/recomputeModel.svg').default} alt="3D Model" style={{ opacity: 0.6, width: 'var(--24, 24px)', height: 'var(--24, 24px)' }} />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                height: 'var(--24, 24px)',
                                padding: '3px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px',
                                cursor: newLoading ? 'pointer' : 'auto',

                            }}
                            onClick={reloadDefaultModel}
                        >
                            <img src={require('../../../../../assets/images/svgs/deleteModel.svg').default} alt="3D Model" style={{ opacity: 0.6, width: 'var(--16, 16px,)', height: 'var(--16, 16px,)' }} />
                        </div>
                    </div>
                </div>
            )
            }
            <div
                id='infoDiv'
                style={{
                    position: 'absolute',
                    top: '60px',
                    right: '50px',
                    display: hover ? 'flex' : 'none',
                    padding: '12px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '12px',
                    borderRadius: 'var(--8, 8px)',
                    opacity: '0px',
                    background: 'rgba(240, 240, 240, 0.10)',
                    backdropfilter: 'blur(20px)',

                }}>
                <img src={require('../../../../../assets/images/svgs/upload-btn-info.svg').default} alt="Upload Model Info" style={{ width: 'var(--16, 16px)', height: 'var(--16, 16px)' }} />
                <span style={{
                    color: '#FFF',
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                }}>
                    File format should be glb/gltf. with maximum file size of 100 MB
                </span>

            </div>
            {
                (model) && (
                    <Canvas camera={{ position: [0, 0, 15], fov: 75 }}>
                        <ambientLight />
                        <pointLight position={[10, 0, 10]} />
                        <OrbitControls />
                        {model && <primitive object={model} position={[0, 0, 0]} />} {/* Model centered */}
                    </Canvas>
                )
            }
            {
                !model && <Common3DSat busSize={Satellite.Template?.['template-attribute']?.['bus-type']} size={busSizeUsage + payloadSizeUsage} canvas_height="100vh" canvas_width="50vw" isPayloadChanges="false" cubeSize={isCloneTemplate ? 0 : 1} />
            }
        </div >
    );
}

export default FileUploader;