//@ts-nocheck
import ModalFooter from '../../../commonComponents/ModalFooter';
import ModalHeader from '../../../commonComponents/ModalHeader';
import { DatePicker } from 'rsuite';
import { ActiveSatellite, CloseIcon, CloseSingleViewIcon, HeaderGroundContactAnalysisIcon, NormalGroundStation, NormalSatellite } from '../../../commonMMSvgs/MissionModelingSvgs';
import './groundStationAnalysis.css'
import ModalInput from '../../../commonComponents/ModalInput';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { gs_visualization_presentation } from '../../Sidebar/GroundStationCreation/Utils';
import * as mmActions from '../../../../../store/MissionModellingSlice.js';
import MissionModellingService from '../../../MissionModelling.service';
import { useParams } from 'react-router-dom';

const GroundStationAnalysis = ({ closeModalCreation, mm_viewer }) => {
    const dispatch = useDispatch()
    const params = useParams()

    const {
        mission_id,
        scenario_id
    } = params;

    const selectedConstellationForCoverage = useSelector(state => state.missionModelling.selectedConstellationsForCoverage)
    const selectedGroundStationForCoverage = useSelector(state => state.missionModelling.selectedGroundStationForCoverage)

    const [gsDate, setGsDate] = useState(Math.floor(Date.now() / 1000))
    const [duration, setDuration] = useState()

    const handleRemoveGS = (groundstation) => {
        dispatch(mmActions.removeSelectedGroundStationForCoverage(groundstation))
        gs_visualization_presentation().selected_gs(groundstation.groundStationId, mm_viewer)
    }

    const handleRemoveConstellation = (constellation) => {
        dispatch(mmActions.removeSelectedConstellationsForCoverage(constellation))
    }

    const handleDateChange = (date) => {
        if (date) {
            const utcDate = new Date(date).toISOString();
            const epochTime = new Date(utcDate).getTime();
            setGsDate(epochTime / 1000)
        }
    }

    const handleSubmit = () => {
        let constellationId = []
        let groundStationId = []

        selectedConstellationForCoverage?.map((item, index) => {
            if (item?.type === 'Single') {
                let satelliteId = item?.groups[0]?.satellites[0]?.id
                constellationId.push({ Id: satelliteId, Type: item?.type })
            } else {
                constellationId.push({ Id: item?.constellationId, Type: item?.type })
            }
        })

        selectedGroundStationForCoverage?.map((gs, index) => {
            groundStationId.push(gs.groundStationId)
        })

        let body = {
            "constellations": constellationId,
            "groundstationIds": groundStationId,
            "startTime": gsDate,
            "duration": parseFloat(duration)
        }
        dispatch(MissionModellingService.groundStationAnalysis(mission_id, scenario_id, closeModalCreation, body))
    }

    return (
        <div className='ground_statiopn_analysis_container'>
            <ModalHeader
                icon={<HeaderGroundContactAnalysisIcon />}
                title={"Ground Contact Analysis"}
                handleClose={() => { closeModalCreation() }}
                handleMinimise={() => { }} />
            <div className='ground_statiopn_analysis_container_body'>
                <div className='inner_section'>
                    <div className='section_title'>
                        <NormalSatellite />
                        <span>Satellite</span>
                    </div>
                    <div className='inner_container'>
                        {selectedConstellationForCoverage?.map((constellation, index) => (
                            <div className='single_view'>
                                <span>
                                    {constellation?.name}
                                </span>
                                <div onClick={() => { handleRemoveConstellation(constellation) }}>
                                    <CloseSingleViewIcon />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='inner_section'>
                    <div className='section_title'>
                        <NormalGroundStation />
                        <span>Ground Station</span>
                    </div>
                    <div className='inner_container'>
                        {selectedGroundStationForCoverage?.map((groundStation, index) => (
                            <div className='single_view'>
                                <span>
                                    {groundStation.groundStationName}
                                </span>
                                <div onClick={() => { handleRemoveGS(groundStation) }}>
                                    <CloseSingleViewIcon />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='other_section'>
                    <div className='timeframe_section'>
                        <span>
                            Start Time
                        </span>
                        <div>
                            <DatePicker format="MM/dd/yyyy HH:mm"
                                onChange={(date) => {
                                    handleDateChange(date)
                                }}
                                value={new Date(gsDate * 1000)}
                                cleanable={true}
                            />
                        </div>
                    </div>
                    <div className='timeframe_section'>
                        <span>
                            Duration
                        </span>
                        <div>
                            <ModalInput
                                unit='Hrs.'
                                value={duration}
                                handleChange={(value) => { setDuration(value) }} />
                        </div>
                    </div>
                </div>
            </div>
            <ModalFooter
                closingText='Close'
                actionText='Analyse'
                loadingText='Analysing '
                isValid={true}
                handleClose={() => { closeModalCreation() }}
                handleSubmit={() => { handleSubmit() }} />
        </div>
    );
};

export default GroundStationAnalysis;
