//@ts-nocheck
import ModalFooter from '../../../commonComponents/ModalFooter';
import ModalHeader from '../../../commonComponents/ModalHeader';
import { DatePicker } from 'rsuite';
import { ActiveSatellite, CloseIcon, CloseSingleViewIcon, GeofenceSvg, HeaderCoverageIcon, NormalConstellation, NormalGroundStation, NormalSat, NormalSatellite } from '../../../commonMMSvgs/MissionModelingSvgs';
import './coverage.css'
import ModalInput from '../../../commonComponents/ModalInput';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as mmActions from '../../../../../store/MissionModellingSlice.js';
import { useParams } from 'react-router-dom';
import MissionModeling from '../../../MissionModeling';
import MissionModellingService from '../../../MissionModelling.service';
import { gs_visualization_presentation } from '../../Sidebar/GroundStationCreation/Utils';

const CoverageAnalysis = ({ closeModalCreation, mm_viewer }) => {

    const dispatch = useDispatch()
    const params = useParams()
    const { mission_id, scenario_id } = params;

    const [activeAnalyis, setActiveAnalysis] = useState('Imaging')
    const [duration, setDuration] = useState('')
    const [startTime, setStartTime] = useState(Math.floor(Date.now() / 1000))

    const selectedConstellationForCoverage = useSelector(state => state.missionModelling.selectedConstellationsForCoverage)
    const selectedGeoFeaturesForCoverage = useSelector(state => state.missionModelling.selectedGeoFeaturesForCoverage)


    const handleRemoveConstellation = (constellation) => {
        dispatch(mmActions.removeSelectedConstellationsForCoverage(constellation))
    }

    const handleRemoveGeoFeature = (geoFeature) => {
        dispatch(mmActions.removeSelectedGeoFeatureForCoverage(geoFeature))
        gs_visualization_presentation().selected_aois(geoFeature.areaOfInterestId, mm_viewer)
    }

    const handleDateChange = (date) => {
        if (date) {
            const utcDate = new Date(date).toISOString();
            const epochTime = new Date(utcDate).getTime();
            setStartTime(epochTime)
        }
    }

    const handleDuration = (value) => {
        setDuration(value)
    }

    const isValidInputs = () => {
        if (selectedConstellationForCoverage?.length > 0 && selectedGeoFeaturesForCoverage?.length > 0 && duration > 0) {
            return true
        }
        return false
    }

    const handleSubmit = () => {
        let body;
        let constellationId = [];
        let geoFetaureId = [];

        selectedConstellationForCoverage?.map((item, index) => {
            if (item?.type === 'Single') {
                let satelliteId = item?.groups[0]?.satellites[0]?.id
                constellationId.push({ objectId: satelliteId, objectType: item?.type })
            } else {
                constellationId.push({ objectId: item?.constellationId, objectType: item?.type })
            }
        })

        selectedGeoFeaturesForCoverage?.map((item, index) => {
            geoFetaureId.push({ aoiId: item?.areaOfInterestId })
        })

        body = {
            constellations: constellationId,
            aoiobjects: geoFetaureId,
            startTime: startTime / 1000,
            duration: duration
        }

        dispatch(MissionModellingService.coverageAnalysis(mission_id, body, scenario_id, closeModalCreation))
    }

    return (
        <div className='coverage_analysis_container'>
            <ModalHeader
                icon={<HeaderCoverageIcon />}
                title={"Coverage Analysis"}
                handleClose={() => { closeModalCreation() }}
                handleMinimise={() => { }} />

            <div className='coverage_analysis_container_body'>
                <div className='immaging_comm_section'>
                    <div className='immaging_com_subsection' onClick={() => { setActiveAnalysis('Imaging') }}>
                        <div className={`imagging_com_section_svg_container ${activeAnalyis === 'Imaging' ? `active_container` : ``}`}>
                            <ActiveSatellite />
                        </div>
                        <span className={`${activeAnalyis === 'Imaging' ? `active_container_text` : `container_text`}`}>
                            Imaging
                        </span>
                    </div>

                    <div className='immaging_com_subsection disable_text'>
                        <div className={`imagging_com_section_svg_container ${activeAnalyis === 'Communication' ? `active_container` : ``}`}>
                            <NormalConstellation />
                        </div>
                        <span className='container_text'>
                            Communication
                        </span>
                    </div>
                </div>
                <div className='inner_section'>
                    <div className='section_title'>
                        <NormalSatellite />
                        <span>Satellite</span>
                    </div>
                    <div className='inner_container'>

                        {selectedConstellationForCoverage?.map((item, index) => (
                            <div className='single_view'>
                                <span>
                                    {item?.name}
                                </span>
                                <div onClick={() => { handleRemoveConstellation(item) }}>
                                    <CloseSingleViewIcon />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='inner_section'>
                    <div className='section_title'>
                        <GeofenceSvg />
                        <span>Geo-Features</span>
                    </div>
                    <div className='inner_container'>

                        {selectedGeoFeaturesForCoverage?.map((item, index) => (
                            <div className='geofense_single_view'>
                                <span>
                                    {item?.name}
                                </span>
                                <div onClick={() => { handleRemoveGeoFeature(item) }}>
                                    <CloseSingleViewIcon color='#7AB3FF' />
                                </div>
                            </div>
                        ))}

                    </div>
                </div>

                <div className='other_section'>
                    <div className='timeframe_section'>
                        <span>
                            Start Time
                        </span>
                        <div>
                            <DatePicker
                                format="MM/dd/yyyy HH:mm:ss"
                                onChange={(date) => {
                                    handleDateChange(date)
                                }}
                                value={new Date(startTime)}
                                cleanable={false}
                            />
                        </div>
                    </div>
                    <div className='timeframe_section'>
                        <span>
                            Duration
                        </span>
                        <div>
                            <ModalInput unit='Hrs.' value={duration} handleChange={(value) => { handleDuration(value) }} />
                        </div>
                    </div>
                </div>
            </div>

            <ModalFooter
                isValid={isValidInputs()}
                closingText='Close'
                actionText='Analyse'
                loadingText='Analysing '
                handleClose={() => { closeModalCreation() }}
                handleSubmit={() => { handleSubmit() }} />
        </div>
    );
};

export default CoverageAnalysis;
