//@ts-nocheck
import { useDispatch, useSelector } from 'react-redux';
import { ActiveGroundStation, AddIcon, CloseIcon, DeleteIcon, EditIcon, MenuIcon, MinimizeIcon, MissionIcon, RenameIcon } from '../../../commonMMSvgs/MissionModelingSvgs';
import './groundStationCreationTree.css'
import { useParams } from 'react-router-dom';
import MissionModellingService from '../../../MissionModelling.service';
import { useEffect, useState } from 'react';
import ActionsMenu from '../../../commonComponents/ActionsMenu';
import ModalInput from '../../../commonComponents/ModalInput';
import * as mmActions from '../../../../../store/MissionModellingSlice.js'
import { gs_visualization_presentation } from './Utils';

const GroundStationCreationTree = ({ openedCreationModal, mm_viewer }) => {

    const dispatch = useDispatch()
    const params = useParams()
    const { mission_id } = params;
    const selectedGroundStationForCoverage = useSelector(state => state.missionModelling.selectedGroundStationForCoverage)

    const allGroundStationList = useSelector(state => state.missionModelling.allGroundStationList)
    const [selectedGroundStation, setSelectedGroundstation] = useState('')
    const [renameGSState, setRenameGSState] = useState({
        selectedGroundStationId: '',
        selectedGroundStationName: ''
    })

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.ground_station_main_header')) {
                setSelectedGroundstation('');
            }
        };
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handlePromoteToMission = (groundStation) => {
    }

    const handleEditGs = (groundStation) => {
        dispatch(mmActions.setEditGroundStationObject(groundStation))
    }

    const handleDeleteGS = (groundStation) => {
        dispatch(MissionModellingService.deleteGroundStation(groundStation.groundStationId))
        gs_visualization_presentation().remove_entity_by_id(groundStation.groundStationId, 'GS_Object', mm_viewer)
    }

    const handleRenameGs = (groundStation) => {
        setRenameGSState({
            selectedGroundStationId: groundStation?.groundStationId,
            selectedGroundStationName: groundStation?.groundStationName
        })
    }

    const handleRenameGSClose = () => {
        setRenameGSState({
            selectedGroundStationId: '',
            selectedGroundStationName: ''
        })
    }

    const handleSaveGSName = () => {
        dispatch(MissionModellingService.renameGroundStation(renameGSState.selectedGroundStationId, renameGSState.selectedGroundStationName))
        setRenameGSState({
            selectedGroundStationId: '',
            selectedGroundStationName: ''
        })
    }

    const addGroundStationForCoverage = (groundStation) => {
        if (openedCreationModal.groundStationanlysisModal) {
            dispatch(mmActions.setSelectedGroundStationForCoverage(groundStation))
        }
    }


    const get_selected_gs_list = (groundstations) => {
        const list = groundstations.map(gs => gs?.['groundStationId'])
        return list
    }

    return (
        <div className='ground_station_container'>
            {
                allGroundStationList?.map((groundStation, index) => {
                    return (
                        <>
                            <div className='ground_station_main_header'>

                                <div className='ground_station_left_panel' onClick={() => {
                                    addGroundStationForCoverage(groundStation)
                                    const selected_all_gs = get_selected_gs_list(selectedGroundStationForCoverage)
                                    const selected_gs = selected_all_gs?.length > 0 ? [...selected_all_gs, groundStation?.groundStationId] : [groundStation?.groundStationId]
                                    gs_visualization_presentation().selected_gs(selected_gs, mm_viewer)
                                }}>
                                    <ActiveGroundStation />
                                    {renameGSState.selectedGroundStationId !== groundStation?.groundStationId ?
                                        <span>
                                            {groundStation.groundStationName}
                                        </span>
                                        :
                                        <ModalInput
                                            isRename={true}
                                            value={renameGSState.selectedGroundStationName}
                                            handleChange={(value) => {
                                                setRenameGSState({
                                                    ...renameGSState,
                                                    selectedGroundStationName: value
                                                })
                                            }}
                                            handleClose={handleRenameGSClose}
                                            handleSave={handleSaveGSName}
                                        />}
                                </div>
                                <div className='ground_station_right_panel' onClick={() => {
                                    setSelectedGroundstation(groundStation.groundStationName)
                                }}
                                // onMouseLeave={() => {
                                //     setSelectedGroundstation('')
                                // }}
                                >
                                    <MenuIcon />
                                    {(selectedGroundStation === groundStation.groundStationName) &&
                                        <ActionsMenu
                                            component='groundStation'
                                            handleDelete={() => {
                                                handleDeleteGS(groundStation)
                                            }}
                                            handleRename={() => {
                                                handleRenameGs(groundStation)
                                            }}
                                            handlePromote={() => {
                                                handlePromoteToMission(groundStation)
                                            }}
                                            handleEdit={() => {
                                                handleEditGs(groundStation)
                                            }}
                                        />
                                    }
                                </div>

                            </div >
                        </>
                    )
                })
            }

        </div>
    );
};

export default GroundStationCreationTree;
