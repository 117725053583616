//@ts-nocheck
import { useSelector } from 'react-redux';
import { AddIcon, NormalGroundStation } from '../../../commonMMSvgs/MissionModelingSvgs';
import './groundStationcreation.css'
import GroundStationCreationTree from './GroundStationCreationtree';
import { useEffect } from 'react';
import { gs_visualization_presentation } from './Utils';

const GroundStationCreation = ({ openGroundStationCreationModal, openedCreationModal, mm_viewer }) => {
    const allGroundStationList = useSelector(state => state.missionModelling.allGroundStationList)

    useEffect(() => {
        if (allGroundStationList?.length > 0 && mm_viewer) {
            gs_visualization_presentation().init_all_gs(allGroundStationList, mm_viewer)
        }
    }, [allGroundStationList, mm_viewer])


    return (
        <>
            {/* This is Header of GS section */}
            <div className='GroundStationCreationHeader'>
                <div className='creationState'>
                    <NormalGroundStation />
                    <span className='headerText'>
                        Ground Station
                    </span>
                    <span className='headerSubText'>
                        {`(${allGroundStationList?.length})`}
                    </span>
                </div>
                <div className='addIcon' onClick={() => { openGroundStationCreationModal() }}>
                    <AddIcon />
                </div>
            </div>
            {/* This is Body of GS section */}
            <div className='groundStationCreationBody'>
                {/* This block is display when there is no ground station */}
                {allGroundStationList?.length === 0 && <div className='create_gs_alignment' onClick={() => { openGroundStationCreationModal() }}>
                    <AddIcon />
                    <span>Create Ground Station</span>
                </div>}

                {/* This block is displayed when there is some ground station which we can show into the gs list */}
                {allGroundStationList?.length > 0 && <GroundStationCreationTree openedCreationModal={openedCreationModal} mm_viewer={mm_viewer} />}
            </div>
        </>
    );
};

export default GroundStationCreation;
