
import { CircularProgress } from '@mui/material';

interface NewLoaderProps {
    canvasHeight: any,
    canvasWidth: any
}

const NewLoader: React.FC<NewLoaderProps> = ({ canvasHeight, canvasWidth }) => {
    const style = {
        height: canvasHeight ? canvasHeight : '100vh',
        width: canvasWidth ? canvasWidth : '100wh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
    }

    return (
        <div style={style}>
            <CircularProgress color='inherit' />
            {/* <div className='loader-page'>
            </div> */}
        </div>
    )
}

export default NewLoader