//@ts-nocheck
import React, { useEffect, useState } from "react";
import Visualisation from "./VisualisationSection/Visualisation";
import VisualisationMM from "./visualisation/VisualisationMM";
import SideBar from "./components/Sidebar/Sidebar";
import MapboxView from "./visualisation/MapView";
import { useSelector, useDispatch } from "react-redux";
import GeoFeatureOptions from "./components/Sidebar/GeoFeatureCreation/GeoFeatureOptions";
import MissionModellingService from "./MissionModelling.service";
import { useParams } from "react-router-dom";
import BottomBar from "./components/bottombar/BottomBar";
import './missionModelling.css'
import ReportSection from "./components/report/ReportSection";
import * as mmActions from '../../store/MissionModellingSlice.js';
import NotificationMessage from "./commonComponents/NotificationMessage";

const MissionModeling = () => {

    const params = useParams()
    const dispatch = useDispatch();

    const {
        mission_name,
        mission_id,
        scenario_id
    } = params;

    const [openedCreationModal, setOpenedCreationModal] = useState<object>({
        satelliteCreationModal: false,
        groundStationCreationModal: false,
        coverageAnalysisModal: false,
        groundStationanlysisModal: false,
        ConstellationConfig: false
    });

    const [openedBottomBarModal, setOpenedBottomBarModal] = useState<string>('');
    const [openedReportModal, setOpenedReportModal] = useState<string>('');

    const isGeoFeatureView = useSelector(state => state.missionModelling.isGeoFeatureView);
    const coverageAnalysisReport = useSelector(state => state.missionModelling.coverageAnalysisReport)
    const groundStationAnalysisReport = useSelector(state => state.missionModelling.groundStationAnalysisReport)


    const notification = useSelector(state => state.missionModelling.notification)

    const [mm_viewer, setMMViewer] = useState();
    const isStatusFetching = useSelector(state => state.missionModelling.isAllReportCompleted)

    useEffect(() => {
        dispatch(MissionModellingService.getAllConstellation(mission_id, scenario_id));
        dispatch(MissionModellingService.fetchAois(mission_id));
        dispatch(MissionModellingService.getAllGroundStation())
    }, [dispatch])

    useEffect(() => {
        dispatch(mmActions.resetGeoFeatureView());
    }, [openedCreationModal])

    useEffect(() => {
    }, [notification])

    useEffect(() => {
        let interval;
        if (!isStatusFetching) {
            interval = setInterval(() => {
                dispatch(MissionModellingService.getDeorbitEclipseStatusOverview(mission_id, scenario_id))
            }, 2000);
        } else {
            dispatch(MissionModellingService.getAllOrbit(mission_id, scenario_id))
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isStatusFetching, isGeoFeatureView]);

    return (
        <div className="mission_modelling_container">
            {isGeoFeatureView ? <MapboxView /> : <VisualisationMM setMMViewer={setMMViewer} mm_viewer={mm_viewer} openedCreationModal={openedCreationModal} />}
            {isGeoFeatureView && <GeoFeatureOptions />}

            <SideBar openedCreationModal={openedCreationModal} setOpenedCreationModal={setOpenedCreationModal} mm_viewer={mm_viewer} />
            <BottomBar openedCreationModal={openedCreationModal} setOpenedCreationModal={setOpenedCreationModal} />
            {((Object.keys(coverageAnalysisReport).length > 0) || (Object.keys(groundStationAnalysisReport).length > 0)) && <ReportSection mm_viewer={mm_viewer} />}

            {(notification.isError || notification.isSuccess) && < NotificationMessage />}
        </div>
    );
};

export default MissionModeling;
