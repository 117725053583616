//@ts-nocheck
import { Form } from "react-bootstrap";
import "./modalInput.css"
import { CloseSvg, SaveSvg } from "../commonMMSvgs/MissionModelingSvgs";

interface ModalInputProps { isRename?: boolean, unit?: string, value: string | number, handleChange: (data: string | number) => void, disabled?: boolean, handleSave?: (data: string | number) => void, handleClose?: (data: string | number) => void }

const ModalInput = ({ isRename, unit, value, handleChange, disabled, handleSave, handleClose }: ModalInputProps) => {

    return (
        <>
            <div className="input_modal_with_unit">
                <input
                    className={`input_modal ${unit ? `required` : `not_required`}`}
                    required
                    type="text"
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            e.preventDefault();
                        }
                    }}
                    disabled={disabled}
                    onChange={(e) => {
                        handleChange(e.target.value)
                    }}
                    value={value}
                />

                {unit && <div
                    className="unit_modal"
                >{unit}</div>}


            </div>

            {isRename && <div className="rename_section">
                <span className="svg_icon_container" onClick={handleSave}>
                    <SaveSvg />
                </span>
                <span className="svg_icon_container" onClick={handleClose}>
                    <CloseSvg />
                </span>
            </div>}
        </>
    );
};

export default ModalInput;