// @ts-nocheck
import { useEffect, useState } from "react";
import "./ModalComponents.css";
import Select from "react-select";

const ModalDropdown = ({ options, isMulti, handleSelectedOption, previousOption }) => {
    const [selectedOption, setSelectedOption] = useState<any>(null); // Set initial state to null

    const handleSelection = (e) => {
        setSelectedOption(e);
        handleSelectedOption(e);
    };

    useEffect(() => {
        if (previousOption) {
            setSelectedOption({
                label: previousOption,
                value: previousOption,
            });
        }
    }, [previousOption]);

    return (
        <Select
            className="modalSelectBtn"
            classNamePrefix="Select"
            placeholder="Select Dropdown"
            value={selectedOption}
            options={options}
            onChange={handleSelection}
            isMulti={isMulti === "true" ? true : false}
        />
    );
};

export default ModalDropdown;