//@ts-nocheck
import { CloseIcon, DeleteIcon, DuplicateIcon, EditIcon, InfoIcon, MinimizeIcon, PrmoteMissionIcon, RenameIcon } from '../commonMMSvgs/MissionModelingSvgs';
import './actionsMenu.css'

interface ActionsInputProps {
    status?: string,
    component: string,
    handleInfo?: (data: string | number) => void,
    handleRename?: (data: string | number) => void,
    handleEdit?: (data: string | number) => void,
    handleDuplicate?: (data: string | number) => void,
    handleDelete?: (data: string | number) => void,
    handlePromote?: (data: string | number) => void
}


const ActionsMenu = ({ status, component, handleInfo, handleRename, handleEdit, handleDuplicate, handleDelete, handlePromote }: ActionsInputProps) => {
    return (
        <div className={`menu_item_container ${component === 'constellation' ? `` : ``}`}>
            {component === "constellation" && status !== "FAILED" && <div className='menu_item'
                onClick={
                    handleInfo
                }>
                <InfoIcon />
                <span>
                    Info
                </span>
            </div>}

            <div className='menu_item'
                onClick={
                    handleRename
                }>
                <RenameIcon />
                <span>
                    Rename
                </span>
            </div>

            <div className='menu_item'
                onClick={
                    handleEdit
                }>
                <EditIcon />
                <span>
                    Edit
                </span>
            </div>

            {component === "constellation" && <div className='menu_item'
                onClick={
                    handleDuplicate
                }>
                <DuplicateIcon />
                <span>
                    Duplicate
                </span>
            </div>
            }
            <div className='menu_item'
                onClick={
                    handleDelete
                }>
                <DeleteIcon />
                <span>
                    Delete
                </span>
            </div>

            {/* {component === "groundStation" && <div className='menu_item'
                onClick={
                    handlePromote
                }>
                <PrmoteMissionIcon />
                <span>
                    Promote to Mission
                </span>
            </div>} */}
        </div>
    )
};

export default ActionsMenu; 