//@ts-nocheck
import { useEffect, useState } from 'react';
import ModalFooter from '../../../commonComponents/ModalFooter';
import ModalHeader from '../../../commonComponents/ModalHeader';
import ModalInput from '../../../commonComponents/ModalInput';
import { ConfigSatIcon } from '../../../commonMMSvgs/MissionModelingSvgs';
import './satelliteConfig.css'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MissionModellingService from '../../../MissionModelling.service';

const SatelliteConfig = ({ closeModalCreation }) => {
    const dispatch = useDispatch()
    const params = useParams()

    const { mission_id, scenario_id } = params;
    const editConstellationConfig = useSelector(state => state.missionModelling.editConstellationConfig)


    const [swath, setSwath] = useState('')
    const [orientation, setOrientation] = useState('nadir')

    const checkIsValid = () => {
        if (swath?.length > o && orientation?.length > 0) {
            return true
        } else {
            return false
        }
    }

    const handleSubmit = () => {
        // Add The Patch API Here
        let satelliteSwath = editConstellationConfig?.groups?.[0]?.satellites?.[0]?.config

        let body = {
            config: {
                swath: swath,
                orientation: orientation
            }
        }

        dispatch(MissionModellingService.editConstellationConfig(editConstellationConfig?.constellationId, mission_id, scenario_id, body, closeModalCreation))

    }

    useEffect(() => {
        if (Object.keys(editConstellationConfig).length > 0) {
            setSwath(editConstellationConfig?.groups[0]?.satellites[0]?.config?.swath)
        }
    }, [editConstellationConfig])

    return (
        <>
            <ModalHeader
                icon={<ConfigSatIcon />}
                title={'Config'}
                handleClose={() => { closeModalCreation() }}
            />

            <div className='satellite_config_item'>
                <span>Swath</span>
                <ModalInput
                    unit='km'
                    value={swath}
                    handleChange={(value) => {
                        setSwath(value)
                    }}
                />
            </div>
            {/* <div className='satellite_config_item'>
                <span>Orientation</span>
                <ModalInput
                    value={orientation}
                    handleChange={() => { }}
                />
            </div> */}

            <ModalFooter
                isValid={() => { checkIsValid() }}
                closingText={'Close'}
                actionText={'Save'}
                loadingText={'Saving ...'}
                handleSubmit={() => { handleSubmit() }}
                handleClose={() => { closeModalCreation() }}
            />
        </>
    );
};

export default SatelliteConfig;
