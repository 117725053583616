import { CloseIcon } from '../commonMMSvgs/MissionModelingSvgs';
import './modalHeader.css'

const ModalHeader = ({ icon, title, handleMinimise, handleClose }) => {
    return (
        <div className='modal_header_main_container'>
            <div className='modal_header_left_panel'>
                {icon}
                <span>
                    {title}
                </span>
            </div>

            <div className='modal_header_right_panel'>
                <div className='close_icon' onClick={() => {
                    handleClose()
                }}>
                    <CloseIcon />
                </div>
            </div>

        </div>
    )
};

export default ModalHeader; 