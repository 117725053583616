import { Fragment, useState, useEffect } from "react";
import CustomSelect, { Option } from "../../../../custom_components/CustomSelect";
import useUrlParams from "../../../../hooks/useUrlParams";

import { v4 as uuidv4 } from "uuid";
import './Console.scss'

interface PayloadFormProps {
    allPayloadComponents: any[];
}
const PayloadForm: React.FC<PayloadFormProps> = ({ allPayloadComponents }) => {

    const [selectedPayload, setSelectedPayload] = useState<Option | null>(null);
    const [sequences, setSequences] = useState<any>([]);
    const [selectedSequences, setSelectedSequences] = useState<Option | null>(null);
    const [seqParams, setSeqParams] = useState<any>('')

    const [seqPowerReq, setSeqPowerReq] = useState<any>('')
    const [seqDuration, setSeqDuration] = useState<any>(1)
    const [OtherInput, setOtherInput] = useState<any>(false)
    const [otherSequence, setOtherSequence] = useState<any>('')

    const { mission_name, mission_id, sat_name, sat_id, truetwin_name, truetwin_id, dashboard, scenario_name, scenario_id } = useUrlParams()

    useEffect(() => {
        sessionStorage.setItem('currentForm', 'payload');
        window.dispatchEvent(new Event('storageUpdate'));
    }, []);

    const setPayloadFormData = (data) => {
        const formData = [{
            "TruetwinId": truetwin_id,
            "LinkId": "",
            "scenarioId": scenario_id,
            "Timestamp": null,
            "CommandUid": uuidv4(),
            "Type": "completeJson",
            "ScheduleId": "default",
            "TaskId": "default",
            "sequenceData": data?.sequenceData
        }];
        sessionStorage.setItem('formData', JSON.stringify(formData));
        return formData;
    }

    const handleManualInputChange = (value) => {
        console.log("Sequence ID received:", value);
        const newData = {
            commandName: selectedPayload?.value?.payload?.name || 'Unknown Payload',
            commandType: "Payload",
            sequenceData: {
                commandId: 600,
                commandData: {
                    App_id: selectedPayload?.value?.payload?.appId,
                    Sequence_id: value,
                    Sequence_params: '',
                    Scheduled_deadline_min: Math.floor(seqDuration / 60),
                    Scheduled_deadline_sec: seqDuration % 60,
                    Scheduled_deadline_ms: (seqDuration % 60) * 1000,
                },
                commandHeader: {
                    SaId: 1,
                    DaId: 134,
                    Qos: 3,
                },
            }
        };
        setPayloadFormData(newData);
        window.dispatchEvent(new Event('storage'));
    };
    useEffect(() => {
        sessionStorage.setItem('selectedPayload', JSON.stringify(selectedPayload));
        sessionStorage.setItem('selectedSequences', JSON.stringify(selectedSequences));
        sessionStorage.setItem('seqParams', (seqParams));
        sessionStorage.setItem('seqPowerReq', (seqPowerReq));
        sessionStorage.setItem('seqDuration', (seqDuration));
        window.dispatchEvent(new Event('storageUpdate'));
    }, [selectedPayload, selectedSequences, seqParams, seqPowerReq, seqDuration]);

    useEffect(() => {
        const clearFormFields = () => {
            {/* This commented code might be needed in the future if commands need to be cleared after execution. */ }
            // setSelectedPayload(null);
            // setSelectedSequences(null);
            // setSeqParams('');
            // setSeqPowerReq('');
            // setSeqDuration('');
            // setOtherSequence('');
            // setOtherInput(false);
            // sessionStorage.setItem('value', '');
            // sessionStorage.setItem('currentForm', 'payload');
        };

        window.addEventListener('clearFormFields', clearFormFields);
        return () => {
            window.removeEventListener('clearFormFields', clearFormFields);
        };
    }, []);
    return (
        <Fragment>
            <div className="left__panel__body__input__option__container">
                <span className="input__label">Payloads</span>
                <div className='input__box__container'>
                    <CustomSelect options={allPayloadComponents} value={selectedPayload} onChange={(data: Option) => {

                        setSelectedPayload(data)
                        const sequencesData = data?.value?.sequence?.sequences;
                        let sequences: Option[] = [];

                        if (Array.isArray(sequencesData)) {
                            sequences = sequencesData.map((sequence) => ({
                                label: sequence?.seqName,
                                value: sequence
                            }));
                        }
                        setSequences([...sequences, { label: 'Other Sequence', value: {}, style: 'unique_option' }]);
                        sessionStorage.setItem('selectedPayload', JSON.stringify(data))
                        window.dispatchEvent(new Event('storage'))
                    }} />
                    <span className='input__feedback'></span>
                </div>
            </div>
            {!OtherInput && <div className="left__panel__body__input__option__container">
                <span className="input__label">Sequences</span>
                <div className='input__box__container'>
                    <CustomSelect options={sequences} value={selectedSequences} onChange={(data) => {
                        if (data?.label === "Other Sequence") {
                            setOtherInput(true)
                        }
                        setSelectedSequences(data)
                        sessionStorage.setItem('selectedSequences', JSON.stringify(data))
                        if (data?.value?.seqParams) {
                            setSeqParams(data?.value?.seqParams)
                            sessionStorage.setItem('seqParams', data?.value?.seqParams);
                        }
                        if (data?.value?.seqPowerRequirements) {
                            setSeqPowerReq(data?.value?.seqPowerRequirements)
                            sessionStorage.setItem('seqPowerReq', data?.value?.seqPowerRequirements);
                        }
                        if (data?.value?.seqDuration) {
                            setSeqDuration(data?.value?.seqDuration)
                            sessionStorage.setItem('seqDuration', data?.value?.seqDuration);
                        }
                        let totalSeconds = data?.value?.seqDuration ? data?.value?.seqDuration : seqDuration;
                        const totalMinutes = Math.floor(totalSeconds / 60);
                        const remainingSeconds = totalSeconds % 60;
                        const remainingMilliseconds = (totalSeconds - totalMinutes * 60 - remainingSeconds) * 1000;
                        let newData = {
                            commandName: selectedPayload?.value?.payload?.name,
                            commandType: "Payload",
                            sequenceData: {
                                commandId: 600,
                                commandData: {
                                    App_id: selectedPayload?.value?.payload?.appId,
                                    Sequence_id: data?.value?.seqName,
                                    Sequence_params: data?.value?.seqParams,
                                    Scheduled_deadline_min: totalMinutes,
                                    Scheduled_deadline_sec: remainingSeconds,
                                    Scheduled_deadline_ms: remainingMilliseconds,
                                },
                                commandHeader: {
                                    SaId: 1,
                                    DaId: 134,
                                    Qos: 3,
                                },
                            }
                        };
                        setPayloadFormData(newData);
                        window.dispatchEvent(new Event('storage'));
                    }} />
                    <span className='input__feedback'></span>
                </div>
            </div>}
            {OtherInput && <div className="left__panel__body__input__option__container">
                <span className="input__label">Sequence</span>
                <div className='input__box__container'>
                    <input className='input__box' placeholder="" value={otherSequence} onChange={(e) => {
                        const value = e.target.value;
                        setOtherSequence(value);
                        handleManualInputChange(value);
                        sessionStorage.setItem('value', value)
                        window.dispatchEvent(new Event('storage'))
                    }} />
                    <div className="input-close-button"
                        onClick={() => {
                            setOtherInput(false);
                            setSelectedSequences(null);
                            setSeqParams('');
                            setSeqPowerReq('');
                            setSeqDuration(0);
                        }}>
                        <i className="fe fe-x close-icon fs-14"></i>
                    </div>
                    <span className='input__feedback'></span>
                </div>
            </div>}
            <div className="left__panel__body__input__option__container">
                <span className="input__label">Parameter</span>
                <div className='input__box__container'>
                    <input className='input__box' placeholder="Parameters" value={seqParams} onChange={(e) => { setSeqParams(e.target.value) }} />
                    <span className='input__feedback'></span>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-between w-100 gap-2">
                <div className="left__panel__body__input__option__container w-48">
                    <span className="input__label">Duration (seconds)</span>
                    <div className='input__box__container'>
                        <input className='input__box' placeholder="Duration" value={seqDuration} onChange={(e) => { setSeqDuration(e.target.value) }} />
                        <span className='input__feedback'></span>
                    </div>
                </div>
                {/* <div className="left__panel__body__input__option__container w-48">
                    <span className="input__label">Min Duration</span>
                    <div className='input__box__container'>
                        <input className='input__box' placeholder="Min duration" value={seqDuration} onChange={(e) => { setSeqDuration(e.target.value) }} />
                        <span className='input__feedback'></span>
                    </div>
                </div> */}
            </div>
            {/* <div className="left__panel__body__input__option__container">
                <span className="input__label">Power</span>
                <div className='input__box__container'>
                    <input className='input__box' placeholder="Power" value={seqPowerReq} onChange={(e) => { setSeqPowerReq(e.target.value) }} />
                    <span className='input__feedback'></span>
                </div>
            </div> */}
        </Fragment>
    )
}

export default PayloadForm