//@ts-nocheck
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GeofenceSvg, GroundTargetSvg, MenuIcon } from '../../../commonMMSvgs/MissionModelingSvgs';
import ModalInput from '../../../commonComponents/ModalInput';
import ActionsMenu from '../../../commonComponents/ActionsMenu';
import { useEffect, useState } from 'react';
import * as mmActions from '../../../../../store/MissionModellingSlice';
import MissionModellingService from '../../../MissionModelling.service';
import { gs_visualization_presentation } from '../GroundStationCreation/Utils';

const GeoFeatureCreationTree = ({ mm_viewer, openedCreationModal }) => {
    const params = useParams()
    const dispatch = useDispatch()

    const {
        mission_name,
        mission_id
    } = params;

    const mode = useSelector(state => state.map.mode);
    const localAoiItem = useSelector(state => state.map.localAoiItem);
    const showLocalAoiItem = useSelector(state => state.map.showLocalAoiItem);
    const selectedGeoFeaturesForCoverage = useSelector(state => state.missionModelling.selectedGeoFeaturesForCoverage)

    const aois = useSelector(state => state.missionModelling.aois);
    const selectedFeature = useSelector(state => state.missionModelling.selectedFeature);
    const aoiCollection = useSelector(state => state.missionModelling.aoiCollection);

    const [selectedAoi, setSelectedAoi] = useState('')
    const [selectedAoiForRename, setSelectedAoiForRename] = useState({
        areaOfInterestId: '',
        name: ''
    })


    const handleDeleteAOI = (aoi) => {
        dispatch(MissionModellingService.deleteAreaOfInterest(aoi.areaOfInterestId, mission_id))
        gs_visualization_presentation().remove_entity_by_id(aoi.areaOfInterestId, 'AOI_Object', mm_viewer)
    }

    const handleRename = (aoi) => {
        setSelectedAoiForRename({
            areaOfInterestId: aoi.areaOfInterestId,
            name: aoi.name
        })
    }

    const handleAoiClose = () => {
        setSelectedAoiForRename({
            areaOfInterestId: '',
            name: ''
        })
    }

    const handleAoiSave = () => {
        dispatch(MissionModellingService.renameAreaOfInterest(selectedAoiForRename.areaOfInterestId, selectedAoiForRename.name, mission_id))
        setSelectedAoiForRename({
            areaOfInterestId: '',
            name: ''
        })
    }

    const handleEditAoi = (aoi) => {
        dispatch(mmActions.setEditAreaOfInterestObject(aoi))
        dispatch(mmActions.setGeoFeatureView(true));
    }

    const addGeoFeatureForCoverage = (geoFeature) => {
        if (openedCreationModal.coverageAnalysisModal) {
            dispatch(mmActions.setSelectedGeoFeatureForCoverage(geoFeature))
        }
    }

    const isItemActive = (item) => {
        return ((Object.keys(selectedFeature)?.length > 0) &&
            (selectedFeature.properties.areaOfInterestId === item.areaOfInterestId));
    };


    const setClickedItemAsFeature = (item) => {
        const selectedItemId = item.areaOfInterestId;
        let selectedFeatureData = [];
        selectedFeatureData = aoiCollection.features.filter((feature) => {
            return feature.properties['areaOfInterestId'] === selectedItemId;
        });
        if (selectedFeatureData?.length > 0) {
            dispatch(mmActions.setSelectedFeature(selectedFeatureData[0]))
        };
    };

    const get_selected_aoi_list = (aois) => {
        const list = aois.map(aoi => aoi?.['areaOfInterestId'])
        return list
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.aoiList')) {
                setSelectedAoi('');
            }
        };
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className='aoiList'>
            {
                aois.map((item, index) => (
                    <div
                        key={index}
                        className={`aoi-item ${isItemActive(item) ? 'active' : ''}`}
                        onClick={() => {
                            setClickedItemAsFeature(item)
                            addGeoFeatureForCoverage(item)
                            const selected_all_aoi = get_selected_aoi_list(selectedGeoFeaturesForCoverage)
                            const selected_aoi = selected_all_aoi?.length > 0 ? [...selected_all_aoi, item?.['areaOfInterestId']] : [item?.['areaOfInterestId']]
                            gs_visualization_presentation().selected_aois(selected_aoi, mm_viewer)
                        }
                        }
                        onMouseLeave={() => {
                            setSelectedAoi('')
                        }}
                    >
                        <div className='geofense_aoi_alignment_state'>
                            <span className='aoi-icon'>
                                {item.type === 'Geofence' ? <GeofenceSvg /> : <GroundTargetSvg />}
                            </span>
                            {selectedAoiForRename.areaOfInterestId !== item?.areaOfInterestId && item.name}
                            {selectedAoiForRename.areaOfInterestId === item?.areaOfInterestId &&
                                <ModalInput
                                    isRename={true}
                                    value={selectedAoiForRename.name}
                                    handleChange={(value) => {
                                        setSelectedAoiForRename({
                                            ...selectedAoiForRename,
                                            name: value
                                        })
                                    }}
                                    handleSave={() => { handleAoiSave() }}
                                    handleClose={() => { handleAoiClose() }}
                                />}
                        </div>

                        <div className='right_heder_icon_aoi'
                            onClick={(e) => {
                                e.stopPropagation()
                                setSelectedAoi(item.name)
                            }}
                        >
                            <MenuIcon />
                        </div>
                        {selectedAoi === item.name &&
                            <ActionsMenu
                                component='Area of interest'
                                handleDelete={() => { handleDeleteAOI(item) }}
                                handleRename={() => { handleRename(item) }}
                                handleEdit={() => { handleEditAoi(item) }}
                            />}
                    </div>
                ))
            }
            {showLocalAoiItem &&
                (<div className='aoi-item active'>
                    <span className='aoi-icon'>
                        {mode === 'draw_polygon' ? <GeofenceSvg /> : <GroundTargetSvg />}
                    </span>
                    {localAoiItem}
                </div>
                )
            }
        </div>
    );
};

export default GeoFeatureCreationTree;
