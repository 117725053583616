//@ts-nocheck
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './groundStationanalysisReport.css'
import { CloseArrow, OpenArrow, ZoomInIcon } from '../../commonMMSvgs/MissionModelingSvgs';
import ModalDropdown from '../../commonComponents/ModalDropdown';

const GroundContactAnalysisReport = ({ mm_viewer }) => {
    const groundStationAnalysisReport = useSelector(state => state.missionModelling.groundStationAnalysisReport)

    const [selectedSatellite, setSelectedSatellite] = useState([])
    const [gsReport, setgsReport] = useState([])

    const [openSection, setOpenSection] = useState({
        input: true,
        output: true
    })

    const getSatelliteOptions = (report) => {
        if (report.type === 'Satellite') {
            return ([{ label: report.satelliteName, value: report }])
        } else {
            let satList = []
            report?.groups[0]?.satellites?.map((satellite, index) => {
                satList.push({ label: satellite?.satelliteName, value: satellite })
            })
            return satList;
        }
    }

    const getGrounsStationOptions = (index) => {
        let groundstationsList = [{ label: 'All', value: selectedSatellite[index]?.value?.allGSAnalysisReport }]

        selectedSatellite[index]?.value?.perGroundStationAnalysisReport?.map((gs, index) => {
            groundstationsList.push({ label: gs?.groundStationName, value: gs })
        })
        return groundstationsList
    }

    useEffect(() => {
        let selectedSatList = []
        let groundStationList = []

        groundStationAnalysisReport?.report?.map((report, index) => {
            if (report?.type === "Constellation") {
                selectedSatList.push({ label: report?.groups[0]?.satellites[0]?.satelliteName, value: report?.groups[0]?.satellites[0] })
                groundStationList.push(report?.groups[0]?.satellites[0]?.allGSAnalysisReport)
            } else {
                selectedSatList.push({ label: report.satelliteName, value: report })
                groundStationList.push(report?.allGSAnalysisReport)
            }
        })
        setSelectedSatellite(selectedSatList)
        setgsReport(groundStationList)

    }, [groundStationAnalysisReport])


    return (
        <div className='gs_report_section'>
            <div className='report_title_section'>
                <div className='date_title'>
                    <div className='report_header_section'>
                        {"Ground Station Analysis"}
                    </div>
                    <div className='report_date_section'>
                        {'abcs'}
                    </div>
                </div>
                <div className='zomm_in_icon'>
                    <ZoomInIcon />
                </div>
            </div>
            <div className='ouput_section'>
                <div className='output_header_block' onClick={() => {
                    setOpenSection({
                        ...openSection,
                        input: !openSection.input
                    })
                }}>
                    <span>
                        Input
                    </span>
                    {openSection.input ? <OpenArrow /> : <CloseArrow />}
                </div>
                {openSection?.input && <div className='overview_section'>
                    <div className='object_style'>
                        Objects
                    </div>

                    <div className='input_object_section'>
                        <div className='input_sections'>
                            <span>
                                {groundStationAnalysisReport?.input?.totalSatellites}
                            </span>
                            <span>
                                Satellites
                            </span>
                        </div>
                        <div className='verticle_devider'></div>
                        <div className='input_sections'>
                            <span>
                                {groundStationAnalysisReport?.input?.totalConstellations}
                            </span>
                            <span>
                                Constellation
                            </span>
                        </div>
                        <div className='verticle_devider'></div>
                        <div className='input_sections'>
                            <span>
                                {groundStationAnalysisReport?.input?.totalGroundStations}
                            </span>
                            <span>
                                Ground Stations
                            </span>
                        </div>
                    </div>

                </div>}
            </div>
            <div className='ouput_section'>
                <div className='output_header_block' onClick={() => {
                    setOpenSection({
                        ...openSection,
                        output: !openSection.output
                    })
                }}>
                    <span>
                        Output
                    </span>
                    {openSection.output ? <OpenArrow /> : <CloseArrow />}
                </div>
                {openSection.output &&
                    <>

                        {selectedSatellite?.length > 0 && groundStationAnalysisReport?.report?.map((report, index) => (
                            <div className='overview_section'>
                                <div className='gs_sat_gs_alignment'>
                                    <span>{report?.type === 'Constellation' ? report?.constellationName : report?.satelliteName} </span>
                                    <div className='modal_alignment_dropdown'>
                                        <ModalDropdown
                                            options={getSatelliteOptions(report)}
                                            handleSelectedOption={(value) => {
                                                const updatedSat = [...selectedSatellite];
                                                updatedSat[index] = value
                                                setSelectedSatellite(updatedSat)
                                            }}
                                            previousOption={selectedSatellite[index]?.label}
                                        />
                                        <ModalDropdown
                                            options={getGrounsStationOptions(index)}
                                            previousOption={'All'}
                                            handleSelectedOption={(gsValue) => {
                                                if (gsValue.label === 'All') {
                                                    const updatedGs = [...gsReport];
                                                    updatedGs[index] = gsValue?.value
                                                    setgsReport(updatedGs)
                                                } else {
                                                    const updatedGs = [...gsReport];
                                                    updatedGs[index] = gsValue?.value?.['analysisReport']
                                                    setgsReport(updatedGs)
                                                }
                                            }}
                                        />
                                    </div>

                                </div>
                                <div>
                                    Overview
                                </div>
                                <div className='overview_detail_section'>
                                    <div className='overview_setion_item'>
                                        <span>
                                            Access Time
                                        </span>
                                        <span>
                                            {gsReport[index]?.totalAccessTime} Min
                                        </span>
                                    </div>
                                    <div className='overview_setion_item'>
                                        <span>
                                            Azimuth-Elevation Range
                                        </span>
                                        <span>
                                            {gsReport[index]?.elevationRange}
                                        </span>
                                    </div>
                                    <div className='overview_setion_item'>
                                        <span>
                                            Avg contact time
                                        </span>
                                        <span>
                                            {gsReport[index]?.avgAccessTime}
                                        </span>
                                    </div>
                                    <div className='overview_setion_item'>
                                        <span>
                                            Max Elevation of the access
                                        </span>
                                        <span>
                                            {gsReport[index]?.maxElevationAngle}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                }
            </div>
        </div >
    );
};

export default GroundContactAnalysisReport;
