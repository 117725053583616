import ModalDropdown from './ModalDropdown';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import MissionService from '../../../../../../service/MissionService';
import { useParams } from 'react-router-dom';
import { scenario_visualization } from '../scenario_visualization/DataSources';
import useScenarioViewer from '../hooks/useScenarioViewer';

dayjs.extend(utc)

interface ScenarioScheduleProps {
    task_plan: string;
    start_time: any;
    duration: any;
    epoch: any;
    scenario_duration?: any;
    imported_schedule_data: any;
    set_scenario_task_plan: (details: any) => void;
    set_simulation_start_time: (date: any) => void;
    set_simulation_duration: (date: any) => void;
    set_imported_schedule: (data: any) => void;
    navigate_scenario: (data: string) => void;
    set_scenario_schedule_data: (data: any[]) => void;
    setUploaddFileStatus: any;
    data_submited: string[];
    scenario_step?: string;
    uploadFileStatus: any;
    setOpenTaskPlanMenu: (data) => void;
    loading: boolean | string;
}

const ScenarioSchedule: React.FC<ScenarioScheduleProps> = ({ task_plan, duration, start_time, epoch, scenario_duration, set_simulation_duration, set_simulation_start_time, set_scenario_schedule_data,
    set_scenario_task_plan, navigate_scenario, data_submited, scenario_step, set_imported_schedule, imported_schedule_data, setUploaddFileStatus, uploadFileStatus, setOpenTaskPlanMenu, loading }) => {

    const { viewer } = useScenarioViewer()
    const params = useParams();
    const {
        sat_id,
    } = params;

    const [TaskPlans, setTaskPlans] = useState<any[]>([]);
    const [selectedOption, setSelectedOption] = useState<any>(null);


    const debounce_simulation_time = (fun: (...args: any[]) => void, delay: number) => {
        let timer: NodeJS.Timeout;
        return function (this: any, ...args: any[]) {
            const context = this;
            clearTimeout(timer);
            timer = setTimeout(() => {
                fun.apply(context, args);
            }, delay);
        };
    };

    const update_simulation_time = (dateTime) => {
        try {
            let simulation_time = new Date(dateTime).getTime()
            set_simulation_start_time(simulation_time)
        }
        catch (error: any) {
            toast.error(error.message, { toastId: "error" });
            set_simulation_start_time('')
            setTimeout(() => {
                set_simulation_start_time(start_time)
            }, 100);
        }
    }
    // Usage
    const debouncedUpdateSimulationTime = debounce_simulation_time(update_simulation_time, 300);



    const get_all_task_plans = () => {
        MissionService.getAllTaskPlan(sat_id)?.then((response) => {
            if (response?.data && response?.["data"]?.length > 0) {
                let alltaskPlans: any = [];
                response?.["data"]?.forEach(taskPlan => {
                    alltaskPlans.push({
                        label: taskPlan?.["taskPlanName"],
                        value: taskPlan
                    });
                })
                const selectedTaskPlan = response?.["data"]?.find((taskPlan) => taskPlan?.value?.["taskPlanId"] === task_plan)
                if (selectedTaskPlan) {
                    setSelectedOption({
                        label: selectedTaskPlan?.value?.["taskPlanName"],
                        value: selectedTaskPlan?.value
                    })
                }
                setTaskPlans(alltaskPlans);
            }
        }).catch((err) => {
            console.log("Error in getting taskplans");
        });
    }

    const upload_json_data = (e: any) => {
        e.stopPropagation()
        setUploaddFileStatus()
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.onchange = (event: any) => {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    const uploadFile = e.target.result;
                    // Do something with the file content
                    const blob = new Blob([uploadFile], { type: "multipart/form-data" });
                    const file_data = new FormData();
                    file_data.append("file", blob, file.name);
                    // setUploadedFile({
                    //     file_data: file_data,
                    //     name: file.name
                    // });
                    set_imported_schedule({
                        file_data: file_data,
                        name: file.name,
                        schedule_upload: false,
                        schedule_imported: true,
                        show_upload_btn: true,
                        show_deploy_btn: false
                    })
                };
                reader.readAsText(file);
            } else {
                toast.error("Invalid file format")
            }
        };
        fileInput.click();
    }

    useEffect(() => {
        get_all_task_plans();
    }, [])

    useEffect(() => {
        if (scenario_step === 'Schedule' && task_plan !== '' && TaskPlans?.length > 0) {
            const selectedTaskPlan = TaskPlans?.find((taskPlan) => taskPlan?.value?.["taskPlanId"] === task_plan)
            if (selectedTaskPlan) {
                setSelectedOption({
                    label: selectedTaskPlan?.value?.["taskPlanName"],
                    value: selectedTaskPlan?.value
                })
            } else {
                set_scenario_task_plan('')
            }
        }
    }, [task_plan, scenario_step, TaskPlans])

    const change_duration = (event) => {
        event.preventDefault()
        if (Number(event.target.value) <= scenario_duration && Number(event.target.value) >= 0) {
            let simulationDoration = Math.abs(+event.target.value)
            set_simulation_duration(event.target.value !== '' ? simulationDoration : '')
        } else {
            toast.error(`Duration should be between 10 and 180 minutes`, { toastId: 'o' })
        }
    }

    return (
        <Fragment>
            <div className="modalBoxShadowDiv">
                <button
                    onClick={() => {
                        if (data_submited?.includes('Schedule')) {
                            navigate_scenario('Schedule')
                        }
                    }}
                    className={`modalCheckboxDiv bg-transparent ${(!data_submited?.includes('Schedule') && scenario_step !== 'Schedule') ? "disabledBtn" : ''}`}>
                    <input type="checkbox" name="checkboxName" id="checkboxId" className="modalCheckbox" />
                    <div className={`modalDropDownIconBtn`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d={scenario_step === 'Schedule' ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                                stroke="#F0F0F0"
                                strokeWidth="1.25"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                    <div className='flex-1 gap-5 d-flex align-items-center justify-content-between'>
                        <div className={"modalCheckboxText modalWhiteSubHeader"}>Schedule</div>
                        {scenario_step === "Schedule" && data_submited?.length >= 5 &&
                            <div className={`d-flex ${data_submited?.includes('Schedule') ? 'pe-8 position-absolute right-0' : 'pe-6'} align-items-center cursor-pointer`} onClick={upload_json_data}>
                                <img src={require("../../../../../../assets/images/svgs/import.svg").default} />
                                <span>Import</span>
                            </div>
                        }
                        {(data_submited?.includes('Schedule') && data_submited?.length === 6) && <img className="greenTickIcon" src={require("../Images/GreenTickIcon.svg").default} />}
                    </div>
                </button>
            </div>

            {!imported_schedule_data?.['schedule_imported'] &&
                <Fragment>
                    {scenario_step === 'Schedule' && (
                        <div className={`d-flex flex-column gap-2 px-4 pb-3 ${loading && 'disable__components'}`}>
                            <div className='modalCheckboxText modalWhiteSubHeader'>Task Plan</div>
                            <ModalDropdown
                                setOpenTaskPlanMenu={(data) => setOpenTaskPlanMenu(data)}
                                options={TaskPlans}
                                selectedOption={selectedOption}
                                handleSelectedOption={(e) => {
                                    set_scenario_task_plan(e.value?.['taskPlanId'])
                                    setSelectedOption(e)
                                    scenario_visualization().selected_aoi_packet(viewer, e.value)
                                }}
                                placeholder='Task plan'
                            />
                        </div>
                    )}
                </Fragment>
            }

            {imported_schedule_data?.['schedule_imported'] && scenario_step === 'Schedule' &&
                <div className={`d-flex flex-column gap-2 px-4 pb-3 ${loading && 'disable__components'}`}>
                    <div className='modalCheckboxText modalWhiteSubHeader'>Imported Schedule</div>
                    <div className='w-100 py-2 bg-black border d-flex align-items-center justify-content-between rounded-2 text-truncate'>
                        <span className='w-90'>{imported_schedule_data?.['name']}</span>
                        <button className='p-2 bg-transparent d-flex align-items-center justify-content-center'
                            onClick={() => {
                                set_imported_schedule({
                                    file_data: null,
                                    name: '',
                                    schedule_upload: false,
                                    schedule_imported: false,
                                    show_upload_btn: false,
                                    show_deploy_btn: false,
                                })
                                set_scenario_schedule_data([])
                            }}
                        >
                            <i className='fe fe-x fs-18' />
                        </button>
                    </div>
                    <span style={{ color: "red", font: "10px" }}> {uploadFileStatus} </span>
                </div>
            }


            {scenario_step === 'Schedule' && (
                <Fragment>
                    <div className={`d-flex flex-column gap-1 px-4 pb-3 w-100 ${loading && 'disable__components'}`}>
                        <div className='d-flex gap-1 align-items-center'>
                            <Fragment>
                                <div className='d-flex gap-2 flex-column w-75'>
                                    <div className='modalCheckboxText modalWhiteSubHeader'> Simulation Start Time</div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <DateTimePicker className="w-100" ampm={false} format='YYYY-MM-DD HH:mm:ss'

                                            shouldDisableDate={(date) => {
                                                const startDateTime = dayjs(epoch);
                                                const endDateTime = dayjs(new Date(epoch).getTime() + ((scenario_duration - duration) * 60000));

                                                return dayjs(date).isBefore(startDateTime, 'day') || dayjs(date).isAfter(endDateTime, 'day');
                                            }}

                                            shouldDisableTime={(date) => {
                                                const startDateTime = dayjs(epoch);
                                                const endDateTime = dayjs(new Date(epoch).getTime() + ((scenario_duration - duration) * 60000));

                                                if (dayjs(date).isSame(startDateTime, 'day')) {
                                                    return dayjs(date).isBefore(startDateTime);
                                                }
                                                if (dayjs(date).isSame(endDateTime, 'day')) {
                                                    return dayjs(date).isAfter(endDateTime);
                                                }
                                                return false;
                                            }}

                                            shouldDisableMonth={(date) => {
                                                const startDateTime = dayjs(epoch);
                                                const endDateTime = dayjs(new Date(epoch).getTime() + ((scenario_duration - duration) * 60000));

                                                return dayjs(date).isBefore(startDateTime, 'month') || dayjs(date).isAfter(endDateTime, 'month');
                                            }}

                                            shouldDisableYear={(date) => {
                                                const startDateTime = dayjs(epoch);
                                                const endDateTime = dayjs(new Date(epoch).getTime() + ((scenario_duration - duration) * 60000));

                                                return dayjs(date).isBefore(startDateTime, 'year') || dayjs(date).isAfter(endDateTime, 'year');
                                            }}
                                            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                                            value={dayjs.utc(start_time)}
                                            // onChange={update_simulation_time}
                                            onChange={(dateTime) => { debouncedUpdateSimulationTime(dateTime) }}
                                        />
                                    </LocalizationProvider>
                                </div>

                                <div className='d-flex gap-2 flex-column w-25'>
                                    <div className='w-100 modalCheckboxText modalWhiteSubHeader'>Duration</div>
                                    <div className='w-100 scenario__time__input__container'>
                                        <input className='w-55 scenario__time__input' type='text' value={duration} onChange={change_duration} />
                                        <span className='w-45'>Mins</span>
                                    </div>
                                </div>
                            </Fragment>
                        </div>
                    </div>
                </Fragment>
            )}


        </Fragment>
    )
}

export default ScenarioSchedule
