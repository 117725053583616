import { useEffect, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import ModalInput from '../../../commonComponents/ModalInput';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

const PolarConstellation = ({ polarConstellation, setPolarConstellation }) => {

    const [ippCheck, setIppCheck] = useState(false)

    const handleTotalSatChange = (value) => {
        setPolarConstellation({
            ...polarConstellation,
            noOfSatellites: value
        })
    }

    const handleRaanChange = (value) => {
        setPolarConstellation({
            ...polarConstellation,
            interAnglePhasingForSinglePlane: value
        })
    }

    return (
        <div style={{ width: "100%" }}>
            <div className='orbital_body_item'>
                <span>Satellite Count</span>
                <div>
                    <ModalInput
                        handleChange={handleTotalSatChange}
                        value={polarConstellation.noOfSatellites} />
                </div>
            </div>
            <div className='orbital_body_item'>
                <div>
                    <span>Internal Plane Phasing</span>
                    <div className='wd_panel_band'>
                        <Form.Check
                            type="checkbox"
                            label=""
                            checked={ippCheck}
                            onChange={(e) => {
                                if (ippCheck === false) {
                                    if (!polarConstellation.noOfSatellites) {
                                        toast.error("Please Enter satellite count")
                                    } else {
                                        setIppCheck(!ippCheck)
                                        setPolarConstellation({
                                            ...polarConstellation,
                                            interAnglePhasingForSinglePlane: (360 / parseFloat(polarConstellation.noOfSatellites))
                                        })
                                    }
                                } else {
                                    setIppCheck(!ippCheck)
                                }
                            }}
                        />
                        <span>Spread across orbit</span>
                    </div>
                </div>
                <div>
                    <ModalInput
                        unit={'0'}
                        handleChange={handleRaanChange}
                        value={polarConstellation.interAnglePhasingForSinglePlane}
                        disabled={ippCheck}
                    />
                </div>

            </div>
        </div>
    );
};

export default PolarConstellation;
