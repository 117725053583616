//@ts-nocheck
import { useEffect, useState } from 'react';
import './coverageAnalysis.css'
import { CloseArrow, CommunicationIcon, ImagingIcon, NormalConstellation, OpenArrow, ZoomInIcon } from '../../commonMMSvgs/MissionModelingSvgs';
import { useSelector } from 'react-redux';
import { gs_visualization_presentation } from '../Sidebar/GroundStationCreation/Utils';

const CoverageAnalysisReport = ({ mm_viewer }) => {

    const coverageAnalysisReport = useSelector(state => state.missionModelling.coverageAnalysisReport)
    const [selectedReport, setSelectedreport] = useState('Imaging')


    const [openSection, setOpenSection] = useState({
        input: true,
        output: true
    })

    return (
        <div className='coverage_report_section'>
            <div className='coverage_header_section'>
                <div className={`coverage_header_section_selection ${selectedReport === 'Imaging' ? `selected_subsection` : ``}`} onClick={() => { setSelectedreport('Imaging') }}>
                    <ImagingIcon />
                    <span>
                        Imaging
                    </span>
                </div>
                <div className={`coverage_header_section_selection not_selectable ${selectedReport === 'Communications' ? `selected_subsection` : ``}`} onClick={() => {
                    // setSelectedreport('Communications')
                }}>
                    <CommunicationIcon />
                    <span>
                        Communications
                    </span>
                </div>
            </div>
            <div className='report_title_section'>
                <div className='date_title'>
                    <div className='report_header_section'>
                        {"Imaging"}
                    </div>
                    <div className='report_date_section'>
                        {coverageAnalysisReport?.input?.startTime}
                    </div>
                </div>
                <div className='zomm_in_icon'>
                    <ZoomInIcon />
                </div>
            </div>

            <div className='ouput_section'>
                <div className='output_header_block' onClick={() => {
                    setOpenSection({
                        ...openSection,
                        input: !openSection.input
                    })
                }}>
                    <span>
                        Input
                    </span>
                    {openSection?.input ? <OpenArrow /> : <CloseArrow />}
                </div>
                {openSection?.input && <div className='overview_section'>
                    <div className='object_style'>
                        Objects
                    </div>

                    <div className='input_object_section'>
                        <div className='input_sections'>
                            <span>
                                {coverageAnalysisReport?.input?.object_count?.sat_count}
                            </span>
                            <span>
                                Satellites
                            </span>
                        </div>
                        <div className='verticle_devider'></div>
                        <div className='input_sections'>
                            <span>
                                {coverageAnalysisReport?.input?.object_count?.const_count}
                            </span>
                            <span>
                                Constellation
                            </span>
                        </div>
                        <div className='verticle_devider'></div>
                        <div className='input_sections'>
                            <span>
                                {coverageAnalysisReport?.areaOfInterests?.length}
                            </span>
                            <span>
                                AOI
                            </span>
                        </div>
                    </div>

                </div>}
            </div>

            <div className='ouput_section'>
                <div className='output_header_block' onClick={() => {
                    setOpenSection({
                        ...openSection,
                        output: !openSection?.output
                    })
                }}>
                    <span>
                        Output
                    </span>
                    {openSection.output ? <OpenArrow /> : <CloseArrow />}
                </div>
                {openSection.output &&
                    coverageAnalysisReport?.areaOfInterests?.map((area, index) => (
                        <>
                            <div className='overview_section'>
                                <span>{area?.name}</span>
                                <div>
                                    Overview
                                </div>
                                <div className='overview_detail_section'>
                                    {coverageAnalysisReport?.input?.duration && <div className='overview_setion_item'>
                                        <span>
                                            Analysis Duration
                                        </span>
                                        <span>
                                            {coverageAnalysisReport?.input?.duration} Hrs.
                                        </span>
                                    </div>}
                                    {area?.percentageCovered && <div className='overview_setion_item'>
                                        <span>
                                            Area covered
                                        </span>
                                        <span>
                                            {area?.percentageCovered?.toFixed(4)} %
                                        </span>
                                    </div>}
                                    {/* <div className='overview_setion_item'>
                                        <span>
                                            Total orbits(s) required
                                        </span>
                                        <span>
                                            {area.numberOfOrbits.toFixed(4)}
                                        </span>
                                    </div> */}
                                    {<div className='overview_setion_item'>
                                        <span>
                                            Total Revisits
                                        </span>
                                        <span>
                                            {area?.revisits}
                                        </span>
                                    </div>}
                                </div>
                            </div>
                            {/* <div className='overview_section'>
                                <div className='overview_section_header_block'>
                                    <span>Coverage Path</span>
                                    <div className='right_side_overview_section'>
                                        <span>
                                            Swath: 24km
                                        </span>
                                        <ZoomInIcon />
                                    </div>
                                </div>
                                <div className='overview_detail_section'>
                                </div>
                            </div> */}
                        </>
                    ))
                }
            </div>

        </div>
    );
};

export default CoverageAnalysisReport;
